import { SupportedChainId } from '../../../web3/supportedChainId'
import { PoolLabels } from '../../pool/PoolLabels'
import { TokenSymbol } from '../../token/TokenSymbols'
import { ARB1_ASSET_CONFIG } from './arbitrum/arb1'
import { AVAX_MAINNET_ASSET_CONFIG } from './avalanche/mainnet'
import { FUJI_ASSET_CONFIG } from './avalanche/testnet'
import { BASE_MAINNET_ASSET_CONFIG } from './base/mainnet'
import { BSC_MAINNET_ASSET_CONFIG } from './bsc/mainnet'
import { BSC_TESTNET_ASSET_CONFIG } from './bsc/testnet'
import { ETHEREUM_MAINNET_ASSET_CONFIG } from './ethereum/mainnet'
import { OPTIMISM_MAINNET_ASSET_CONFIG } from './optimism/mainnet'
import { POLYGON_MAINNET_ASSET_CONFIG } from './polygon/mainnet'
import { SCROLL_MAINNET_ASSET_CONFIG } from './scroll/mainnet'
import { SCROLL_TESTNET_ASSET_CONFIG } from './scroll/testnet'
import { SKALE_TESTNET_ASSET_CONFIG } from './skale/testnet'
// import { ZKSYNC_MAINNET_ASSET_CONFIG } from './zkSync/mainnet'
// import { ZKSYNC_TESTNET_ASSET_CONFIG } from './zkSync/testnet'

export type AssetConfig = {
  [label in PoolLabels]: {
    [id in TokenSymbol]?: {
      color: string
      name: string
      paused?: boolean
      delisted?: boolean
      displaySymbol?: string
      enableVoting?: boolean
      hasBribeV2?: boolean
      interestBearingAprEndpoint?: string
    }
  }
}

/** New Pool Release: Update Asset Config */
/**
 * This config is used to add extra properties to asset instances
 */
export const ASSET_CONFIG: {
  [chainId in SupportedChainId]: AssetConfig
} = {
  [SupportedChainId.BSC_TESTNET]: BSC_TESTNET_ASSET_CONFIG,
  [SupportedChainId.BSC_MAINNET]: BSC_MAINNET_ASSET_CONFIG,
  [SupportedChainId.ARBITRUM_ONE]: ARB1_ASSET_CONFIG,
  [SupportedChainId.FUJI]: FUJI_ASSET_CONFIG,
  [SupportedChainId.ETHEREUM_MAINNET]: ETHEREUM_MAINNET_ASSET_CONFIG,
  [SupportedChainId.SCROLL_SEPOLIA_TESTNET]: SCROLL_TESTNET_ASSET_CONFIG,
  [SupportedChainId.SCROLL_MAINNET]: SCROLL_MAINNET_ASSET_CONFIG,
  [SupportedChainId.SKALE_TESTNET]: SKALE_TESTNET_ASSET_CONFIG,
  [SupportedChainId.OP_MAINNET]: OPTIMISM_MAINNET_ASSET_CONFIG,
  [SupportedChainId.POLYGON_MAINNET]: POLYGON_MAINNET_ASSET_CONFIG,
  [SupportedChainId.AVAX]: AVAX_MAINNET_ASSET_CONFIG,
  [SupportedChainId.BASE]: BASE_MAINNET_ASSET_CONFIG,
  /** @todo unhide it when needed  */
  // [SupportedChainId.ZKSYNC_MAINNET]: ZKSYNC_MAINNET_ASSET_CONFIG,
  // [SupportedChainId.ZKSYNC_TESTNET]: ZKSYNC_TESTNET_ASSET_CONFIG,
}
