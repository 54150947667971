import { AssetConfig } from '..'
import { defaultAssetConfig } from '../defaultAssetConfig'
import { PoolLabels } from '../../../pool/PoolLabels'
import { TokenSymbols } from '../../../token/TokenSymbols'

export const SCROLL_MAINNET_ASSET_CONFIG: AssetConfig = {
  ...defaultAssetConfig,
  [PoolLabels.CROSS_CHAIN]: {
    [TokenSymbols.USDC]: {
      name: 'USD Coin',
      color: '#1D72B9',
      enableVoting: false,
    },
    [TokenSymbols.USDT]: {
      name: 'Tether USD',
      color: '#22B093',
      enableVoting: false,
    },
  },
}
