import { AssetConfig } from '..'
import { PoolLabels } from '../../../pool/PoolLabels'
import { TokenSymbols } from '../../../token/TokenSymbols'
import { defaultAssetConfig } from '../defaultAssetConfig'

export const AVAX_MAINNET_ASSET_CONFIG: AssetConfig = {
  ...defaultAssetConfig,
  [PoolLabels.CROSS_CHAIN]: {
    [TokenSymbols.USDC]: {
      name: 'USD Coin',
      color: '#1D72B9',
      enableVoting: false,
    },
    [TokenSymbols.USDT]: {
      name: 'Tether USD',
      color: '#22B093',
      enableVoting: false,
    },
  },
  [PoolLabels.sAVAX]: {
    [TokenSymbols.WAVAX]: {
      name: 'Wrapped AVAX',
      color: '#F3BA2F',
      enableVoting: false,
      displaySymbol: 'AVAX',
    },
    [TokenSymbols.sAVAX]: {
      name: 'Staked AVAX',
      color: '#2AD4B7',
      enableVoting: false,
    },
  },
  [PoolLabels.USDV]: {
    [TokenSymbols.USDV]: {
      name: 'USDV',
      color: '#D4D4D4',
    },
    [TokenSymbols.USDT]: {
      name: 'USDT',
      color: '#767676',
    },
  },
  [PoolLabels.ggAVAX_AVAX]: {
    [TokenSymbols.ggAVAX]: {
      name: 'ggAVAX',
      color: '#D4D4D4',
    },
    [TokenSymbols.GGP]: {
      name: 'GGP',
      color: '#767676',
    },
  },
}
