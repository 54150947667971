import { ContractTransaction, utils } from 'ethers'
import Image from 'next/image'
import { useEffect, useRef, useState } from 'react'
import { TOKENS } from '../../constants/contract'
import { TokenSymbols } from '../../constants/contract/token/TokenSymbols'
import { VEWOMS } from '../../constants/contract/veWom'
import { useWeb3 } from '../../context/Web3Context'
import useApproval, { TokenApprovalState } from '../../hooks/useApproval'
import useFetchBoostInfo from '../../hooks/useFetchBoostInfo'
import useHandleLock, { HandleLockState } from '../../hooks/useHandleLock'
import useHandleUnLock from '../../hooks/useHandleUnlock'
import { useScrollTo } from '../../hooks/useScroll'
import calculatorIcon from '../../public/assets/icons/calculator.svg'
import dotBetweenIcon from '../../public/assets/icons/vertor-between-button.svg'
import veWOMBigIcon from '../../public/assets/icons/veWOM-boost.svg'
import veWOMGreyBigIcon from '../../public/assets/icons/veWOM-grey-boost.svg'
import WOMicon from '../../public/assets/tokens/WOM.svg'
import { assertUnreachable, getClaimDate, parseStringToBigNumber } from '../../utils'
import { formatNumberUSLocale, replaceString } from '../../utils/numberFormat'
import { decimalAndInteger } from '../../utils/regex'
import BoostCalculatorModal from '../BoostCalculatorModal'
import BoostConfirmationModal from '../BoostConfirmationModal'
import PositionTable from '../PositionTable'
import Slider from '../Slider/Slider'
import Tooltip from '../Tooltip'
import FailedModal from '../TransactionFailedModal'
import SubmittedModal from '../TransactionSubmittedModal'
import WButton, { Variant } from '../WButton'

const maxDays = 1461
const minDays = 7
const dayPerPercent = maxDays / 100
const listDay = [
  {
    '3 months': Math.round((maxDays / 4 / 12) * 3) as number,
  },
  {
    'Half year': Math.round(maxDays / 4 / 2) as number,
  },
  {
    '1 year': Math.round(maxDays / 4) as number,
  },
  {
    '2 years': Math.round(maxDays / 2) as number,
  },
  {
    '3 years': Math.round((maxDays / 4) * 3) as number,
  },
  {
    '4 years': maxDays as number,
  },
]

const BoostPage = () => {
  const { chainId, isSupported, account } = useWeb3()
  const [lockAmountForDisplay, setLockAmountForDisplay] = useState<string>('')
  const [isOpenCalculator, setIsOpenCalculator] = useState(false)
  const [sliderValue, setSliderValue] = useState(0)
  const [lockPeriodValue, setLockPeriodValue] = useState<number | string | null>(null)
  const [isShowActionModal, setIsShowActionModal] = useState(false)
  const { balance, breedings, totalLocked, totalEarning, refreshFetchBoost } = useFetchBoostInfo()
  const isLockedWOM = Number(totalLocked) > 0

  const handleClickCalculator = () => {
    setIsOpenCalculator(true)
  }

  const onTxnSubmited = (txn: ContractTransaction | null) => {
    if (txn != null) {
      txn.wait().then(() => {
        refreshFetchBoost()
      })
    }
  }

  const { lockState, tryHandleLock, veWomEarnings, txnHash, isNewTxn } = useHandleLock(
    parseStringToBigNumber(lockAmountForDisplay, 18).value,
    lockPeriodValue,
    onTxnSubmited
  )

  const { unLockState, tryHandleUnLock, txnHashUnlock, isNewTxnUnlock } =
    useHandleUnLock(onTxnSubmited)

  const amountOnchange = (value: string) => {
    if (value) {
      const replaceVal = replaceString(value, ',', '.')
      if (decimalAndInteger.test(replaceVal)) {
        setLockAmountForDisplay(replaceVal)
      }
    } else {
      setLockAmountForDisplay('')
    }
  }

  useEffect(() => {
    if (!account) {
      setLockAmountForDisplay('')
    }
  }, [account])

  const { approvalState, tryApproval } = useApproval(
    TOKENS[chainId][TokenSymbols.WOM]?.address ?? null,
    VEWOMS[chainId]?.address ?? null,
    parseStringToBigNumber(lockAmountForDisplay, 18).value
  )

  const buttonLeft = () => {
    switch (approvalState) {
      case TokenApprovalState.UNKNOWN:
      case TokenApprovalState.NETWORK_UNSUPPORTED:
      case TokenApprovalState.NOT_APPROVED:
        return (
          <WButton
            variant={isSupported ? Variant.GRADIENT : Variant.LIGHT_PURPLE}
            width="w-full"
            disabled={!isSupported}
            className="boost-approve"
            onClick={() => {
              tryApproval()
            }}
          >
            APPROVE
          </WButton>
        )

      case TokenApprovalState.LOADING:
        return <WButton variant={Variant.GRADIENT} width="w-full" isLoading={true} />

      case TokenApprovalState.APPROVED:
        return (
          <WButton variant={Variant.LIGHT_PURPLE} width="w-full" disabled>
            APPROVED
          </WButton>
        )

      default:
        assertUnreachable(approvalState)
    }
  }

  const shouldShowWarning = () => {
    if (lockPeriodValue && listDay && listDay[0] && listDay[0]['3 months']) {
      if (Number(lockPeriodValue) < minDays || Number(lockPeriodValue) > maxDays) {
        return true
      }
    }
    return false
  }

  const buttonRight = () => {
    switch (true) {
      case approvalState !== TokenApprovalState.APPROVED ||
        !lockAmountForDisplay ||
        !lockPeriodValue ||
        Number(lockAmountForDisplay) == 0 ||
        shouldShowWarning():
        return (
          <WButton variant={Variant.LIGHT_PURPLE} width="w-full" disabled={true}>
            LOCK
          </WButton>
        )

      case lockState === HandleLockState.LOADING:
        return <WButton variant={Variant.GRADIENT} width="w-full" isLoading={true} />

      case approvalState === TokenApprovalState.APPROVED:
        return (
          <WButton
            variant={
              !lockAmountForDisplay || isAmountGreaterThanBalance()
                ? Variant.LIGHT_PURPLE
                : Variant.GRADIENT
            }
            className="boost-lock"
            width="w-full"
            disabled={!lockAmountForDisplay || isAmountGreaterThanBalance()}
            onClick={() => {
              tryHandleLock(() => {
                setLockAmountForDisplay('')
                setLockPeriodValue(null)
                setSliderValue(0)
              })
              setIsShowActionModal(true)
            }}
          >
            LOCK
          </WButton>
        )

      default:
        break
    }
  }

  const renderModal = () => {
    switch (lockState) {
      case HandleLockState.LOADING:
        return (
          <BoostConfirmationModal
            isOpen
            onClose={() => {
              setIsShowActionModal(false)
            }}
            txHash={isNewTxn}
            balances={lockAmountForDisplay ?? null}
            textAboveBalance="Lock WOM"
          />
        )

      case HandleLockState.SUBMITED:
        return (
          <SubmittedModal
            isOpen
            onClose={() => {
              setIsShowActionModal(false)
            }}
            hash={txnHash}
            isAddTokenToWallet={false}
            chainId={chainId}
          />
        )

      case HandleLockState.FAILED:
        return (
          <FailedModal
            isOpen
            onClose={() => {
              setIsShowActionModal(false)
            }}
          />
        )
      default:
        return null
    }
  }

  const renderDays = () =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    listDay?.map((item: any, i) => (
      <button
        key={i}
        className={`${
          item[Object.keys(item)[0]].toString() == lockPeriodValue
            ? 'border-wombatBrown1 bg-wombatPurple1 text-wombatCream'
            : 'border-wombatBrown2'
        } mb-3  rounded-lg border-2 py-1 text-center hover:border-wombatBrown1 hover:bg-wombatPurple1 hover:text-wombatCream`}
        onClick={() => {
          setLockPeriodValue(Math.round(item[Object.keys(item)[0]]))
          setSliderValue(Math.round(item[Object.keys(item)[0]] / dayPerPercent))
        }}
      >
        {Object.keys(item)[0]}
      </button>
    ))

  const handleCallBackSlider = (val: string) => {
    const value = Number(val)
    if (
      listDay &&
      listDay[0] &&
      listDay[0]['3 months'] &&
      listDay[2]['1 year'] &&
      listDay[4]['3 years']
    ) {
      if (value == 0) {
        setLockPeriodValue(minDays)
      } else if (value == 6) {
        setLockPeriodValue(listDay[0]['3 months'])
      } else if (value == 13) {
        setLockPeriodValue(listDay[1]['Half year'] as number)
      } else {
        setLockPeriodValue(Math.round(Number(value) * dayPerPercent))
      }
      setSliderValue(value)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeLockPeriod = (e: any) => {
    const value = e.target.value
    if (parseFloat(value)) {
      if (parseFloat(value) <= Math.pow(maxDays, 2)) {
        setLockPeriodValue(parseFloat(value))
        setSliderValue(Math.round(parseFloat(value) / dayPerPercent))
      }
    } else {
      setLockPeriodValue(null)
    }
  }

  const isAmountGreaterThanBalance = (): boolean => {
    const womBalance = utils.parseEther(balance || '0')
    const lockAmount = utils.parseEther(lockAmountForDisplay || '0')
    if (balance && lockAmountForDisplay) {
      return lockAmount.gt(womBalance)
    }
    return true
  }

  const wrapperRef = useRef(null)
  const { scrollTo } = useScrollTo(wrapperRef)
  const handleMax = () => {
    if (balance) {
      setLockAmountForDisplay(balance)
    }
  }

  return (
    <div className="mx-auto flex w-full max-w-5xl flex-col items-center space-y-8 font-Work">
      <div className="flex w-full max-w-3xl flex-col justify-center py-4 md:py-10">
        <div className="md:min-w-700 mx-auto w-full rounded-2xl text-sm text-wombatBrown md:bg-wombatCream md:p-10 md:shadow-lg">
          <div className="px-14 md:px-0">
            <div className="flex flex-col items-center justify-center">
              <button
                className={`h-[108px] w-[108px] ${
                  !isLockedWOM
                    ? 'bg-wombatCream'
                    : 'button-hover-opacity-light border-2 border-wombatYellow3 bg-wombatYellow2'
                } flex flex-col items-center justify-center rounded-full shadow-circle`}
                onClick={() => {
                  if (isLockedWOM) {
                    scrollTo()
                  }
                }}
              >
                <Image
                  alt={''}
                  src={!isLockedWOM ? veWOMGreyBigIcon : veWOMBigIcon}
                  width={75}
                  height={75}
                />
              </button>
              <div className="mt-4 flex items-center">
                <div className="mr-2 font-semibold text-wombatPurple1">Lock WOM to get veWOM</div>
                <Tooltip>
                  veWOM is a yield booster.
                  <div>
                    Lock WOM for a duration of your choosing, and boost will be applied
                    automatically.
                  </div>
                </Tooltip>
              </div>
            </div>

            <div className="mx-auto mt-6 mb-2 flex w-full flex-col justify-center md:w-1/2">
              {/* WOM Lock Amount */}
              <div className="mb-1 flex items-center">
                <div className="mr-2 font-semibold">WOM Lock Amount</div>
                <div className="">
                  <Tooltip>The amount of WOM you have decided to lock in.</Tooltip>
                </div>
              </div>
              <div className="flex items-center justify-between rounded-md border-2 border-wombatPurple1 p-2 focus-within:border-wombatPurple hover:border-wombatPurple">
                <input
                  type="text"
                  inputMode="numeric"
                  placeholder="0.00"
                  className="w-full bg-transparent font-Helvetica text-base font-medium text-wombatBrown1 placeholder-wombatPurple3 focus:outline-none"
                  value={lockAmountForDisplay}
                  onChange={(e) => amountOnchange(e.target.value)}
                />
                <button
                  onClick={handleMax}
                  className={`button-hover-opacity pl-1 font-semibold text-wombatPurple1`}
                >
                  MAX
                </button>
              </div>
              <div className="my-2 flex items-center text-xs">
                <div>Balance:</div>
                <div className="ml-2 flex">
                  <Image alt={''} src={WOMicon} width={15} height={15} />
                  <div className="ml-1">
                    {/* {account && balance ?  Number(balance).toFixed(2) : '-'} */}
                    {account && balance ? formatNumberUSLocale(balance) : '-'}
                  </div>
                </div>
              </div>

              {/* Lock period */}
              <div className="mt-4 mb-1 flex items-center md:mt-2">
                <div className="font-semibold">Lock period</div>
              </div>

              <div className="flex grow items-center justify-between rounded-md border-2 border-wombatPurple1 p-2 focus-within:border-wombatPurple hover:border-wombatPurple">
                <input
                  className="w-full bg-transparent font-Helvetica text-base font-medium text-wombatBrown1 placeholder-wombatPurple3 focus:outline-none"
                  inputMode="numeric"
                  placeholder="7"
                  onChange={handleChangeLockPeriod}
                  value={lockPeriodValue ?? ''}
                />
                <div className="select-none font-medium text-wombatBrown1">days</div>
              </div>

              <div className="my-2">
                <Slider value={sliderValue.toString()} callBack={handleCallBackSlider} />
              </div>

              <div className="grid w-full grid-cols-3 gap-x-2 md:gap-x-6">{renderDays()}</div>

              {shouldShowWarning() ? (
                <div className="flex items-center justify-center">
                  <div className="ml-1 text-xs text-wombatRed">
                    Input a lock period of 7 days to 4 years
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="flex items-center justify-center">
              <div className="flex w-full flex-col items-center md:w-3/4">
                <div className="mt-5 w-full">
                  <div className="flex justify-between font-semibold">
                    <div className="flex items-center">
                      <div className="mr-2">veWOM Earnings</div>
                      <div>
                        <Tooltip>
                          <span className="font-normal">
                            {"The amount of veWOM you'll receive upon locking your WOM."}
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="text-wombatPurple1">
                      {Number(veWomEarnings) ? `${formatNumberUSLocale(veWomEarnings)} veWOM` : '-'}
                    </div>
                  </div>
                  <div className="mt-2 flex justify-between md:mt-0">
                    <div>Multipler</div>
                    <div>
                      {Number(veWomEarnings)
                        ? (Number(veWomEarnings) / Number(lockAmountForDisplay)).toFixed(3) + 'x'
                        : '-'}
                    </div>
                  </div>
                  <div className="mt-2 flex justify-between md:mt-0">
                    <div>Unlock Date</div>
                    <div></div>
                    {lockPeriodValue && !shouldShowWarning()
                      ? getClaimDate(Number(lockPeriodValue))
                      : '-'}
                  </div>
                </div>
                <button
                  className="button-hover-opacity mt-4 mb-2 flex items-center justify-center md:my-14 md:mt-9 md:mb-0"
                  onClick={handleClickCalculator}
                >
                  <Image alt={''} src={calculatorIcon} width={16} height={16} />
                  <div className="ml-2 text-xs text-wombatPurple1">Booster Calculator</div>
                </button>
                <div className="mt-5 flex w-full items-center justify-between">
                  {buttonLeft()}
                  <div className="flex-shrink-0 pl-[2px] pb-[4px]">
                    <Image alt={''} src={dotBetweenIcon} width={32} />
                  </div>
                  {buttonRight()}
                </div>
                {lockAmountForDisplay && account && isAmountGreaterThanBalance() && (
                  <div className="mt-2 -mb-2 text-center text-red-600">Insufficient balance.</div>
                )}
              </div>
            </div>
          </div>
          <fieldset
            className="mx-2 mt-6 border-t-2 border-wombatPurple2 text-center md:mx-0 md:mt-10 md:w-full"
            ref={wrapperRef}
          >
            <legend>
              <div className="relative w-max p-4 font-Jamjuree text-base font-bold text-wombatPurple">
                MY veWOM Position
              </div>
            </legend>
          </fieldset>
          {!isLockedWOM ? (
            <div className="mb-20 flex w-full items-center justify-center text-xs text-wombatBrown">
              Lock WOM to get veWOM
            </div>
          ) : (
            <PositionTable
              breedings={breedings}
              tryHandleUnLock={tryHandleUnLock}
              unLockState={unLockState}
              totalLocked={totalLocked}
              totalEarning={totalEarning}
              txnHashUnlock={txnHashUnlock}
              isNewTxnUnlock={isNewTxnUnlock}
            />
          )}
        </div>
        {isShowActionModal && renderModal()}
        <BoostCalculatorModal
          isOpen={isOpenCalculator}
          onClose={() => {
            setIsOpenCalculator(false)
          }}
        />
      </div>
    </div>
  )
}

export default BoostPage
