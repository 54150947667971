import { usePoolPage } from '../../context/PoolPageContext'
import Deposit from '../Deposit'
import PoolContainer from '../PoolContainer'
import { POOL_STATE } from '../PoolPage/index'
import Stake from '../Stake'
import Unstake from '../Unstake'
import Withdraw from '../Withdraw'
import { usePoolCard } from '../../context/PoolCardContext'
import { POOLS } from '../../constants/contract/pool'
import { useWeb3 } from '../../context/Web3Context'
import { ASSETS } from '../../constants/contract/asset'
import { useSearchParams } from 'react-router-dom'
import { PoolLabels } from '../../constants/contract/pool/PoolLabels'
import { TokenSymbol, TokenSymbols } from '../../constants/contract/token/TokenSymbols'
import { useEffect } from 'react'
import { GroupedAndSortTokenInfo } from '../PoolList'
import { getSupportedChainIdByNetwork } from '../../constants/web3/chains'

interface ModalProps {
  groupedAndSortedTokens: GroupedAndSortTokenInfo
}

export const Modal = ({ groupedAndSortedTokens }: ModalProps) => {
  const { poolState, setPoolState } = usePoolPage()
  const { chainId } = useWeb3()
  const { depositedAmount, stakedAmount, poolLabel, tokenInfo, handlePoolCardInput } = usePoolCard()
  const onTxnSubmited = () => {
    return
  }
  const [searchParams] = useSearchParams()
  const currentPool = poolLabel && POOLS[chainId][poolLabel]
  const asset = poolLabel && tokenInfo && ASSETS[chainId][poolLabel][tokenInfo.symbol]

  /** Direct to target modal base on query (pool/token/action) */
  useEffect(() => {
    const queryPoolLabels = searchParams.get('pool') as PoolLabels
    const queryTokenSymbol = searchParams.get('token') as TokenSymbol
    const queryAction = searchParams.get('action')?.toLowerCase() as POOL_STATE
    const queryNetwork = searchParams.get('chain')
    const queryNetworkChainId = getSupportedChainIdByNetwork(queryNetwork)

    const isTokenSymbolValid = Object.values(TokenSymbols).some(
      (tokenSymbol) => tokenSymbol === queryTokenSymbol
    )
    const isActionValid = Object.values(POOL_STATE).some((poolState) => poolState === queryAction)
    const isPoolLabelsValid = Object.values(PoolLabels).some(
      (poolLabel) => poolLabel === queryPoolLabels
    )

    if (
      !isTokenSymbolValid ||
      !isActionValid ||
      !isPoolLabelsValid ||
      queryNetworkChainId !== chainId
    )
      return

    const queryTokenInfo = groupedAndSortedTokens[queryPoolLabels].find(
      (tokenInfo) => tokenInfo?.symbol === queryTokenSymbol
    )
    if (!queryTokenInfo) return

    setPoolState(queryAction)
    handlePoolCardInput(queryPoolLabels, queryTokenInfo)

    /** only run in first rendering */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleRedirect = (poolState: POOL_STATE | null) => setPoolState(poolState)
  if (!currentPool || !asset) return null

  return (
    <>
      {poolState === POOL_STATE.DEPOSIT && (
        <PoolContainer nav={[POOL_STATE.DEPOSIT, POOL_STATE.WITHDRAW]}>
          <Deposit
            currentPool={currentPool}
            asset={asset}
            onTxnSubmited={onTxnSubmited}
            redirect={handleRedirect}
          />
        </PoolContainer>
      )}
      {poolState === POOL_STATE.WITHDRAW && (
        <PoolContainer nav={[POOL_STATE.DEPOSIT, POOL_STATE.WITHDRAW]}>
          <Withdraw
            currentPool={currentPool}
            asset={asset}
            onTxnSubmited={onTxnSubmited}
            redirect={handleRedirect}
          />
        </PoolContainer>
      )}
      {poolState === POOL_STATE.STAKE && (
        <PoolContainer nav={[POOL_STATE.STAKE, POOL_STATE.UNSTAKE]}>
          <Stake
            currentPoolLabel={currentPool.label}
            asset={asset}
            tokenInfo={tokenInfo}
            depositedAmount={depositedAmount}
            stakedAmount={stakedAmount}
            onTxnSubmited={onTxnSubmited}
          />
        </PoolContainer>
      )}
      {poolState === POOL_STATE.UNSTAKE && (
        <PoolContainer nav={[POOL_STATE.STAKE, POOL_STATE.UNSTAKE]}>
          <Unstake
            currentPoolLabel={currentPool.label}
            asset={asset}
            tokenInfo={tokenInfo}
            stakedAmount={stakedAmount}
            onTxnSubmited={onTxnSubmited}
            redirect={handleRedirect}
          />
        </PoolContainer>
      )}
    </>
  )
}
