import React, { useState } from 'react'
import { useModalContext } from '../../../context/ModalContext'
import Modal from '../../Modal'
import SelfServiceBribeForm from '../SelfServiceBribeForm'
import { ModalId } from '../../../interfaces/modal'
import { TOKENS } from '../../../constants/contract'
import { useWeb3 } from '../../../context/Web3Context'
import Button from '../../Button'
import { timeConverter } from '../../../utils'
import { getCommifiedFormat } from '@hailstonelabs/big-number-utils'
import { useSelfServiceBribePageContext } from '../../../context/SelfServiceBribePageContext'
import { SECONDS_PER_WEEK } from '../../../constants/common'
import useSelfServiceBribe from '../../../hooks/bribe/useSelfServiceBribe'

type Props = {
  isOpen: boolean
}

function EditEmissionModal({ isOpen }: Props) {
  const { chainId } = useWeb3()
  const {
    actions: { closeModal },
    modalState,
  } = useModalContext()
  const { updateBribeRewardRate } = useSelfServiceBribe()
  const { addedRewardList, isToShowBribeData } = useSelfServiceBribePageContext()
  const [weeklyEmissionInputted, setWeeklyEmissionInputted] = useState('')
  const [isUpdatingEmission, setIsUpdatingEmission] = useState(false)
  const [extendedSeconds, setExtendedSeconds] = useState(0)
  if (modalState.currentModalId !== ModalId.UPDATE_BRIBE_EMISSION_RATE) {
    return <></>
  }
  const { assetSymbol, rewardSymbol, poolSymbol } = modalState.payload
  const targetRewardData = addedRewardList.filter(
    (rewardData) =>
      rewardData.assetSymbol === assetSymbol &&
      rewardData.poolSymbol === poolSymbol &&
      rewardData.rewardSymbol === rewardSymbol
  )[0]
  const rewardDisplaySymbol = TOKENS[chainId][rewardSymbol]?.displaySymbol
  const label = isToShowBribeData ? 'Bribe' : 'Rewarder'
  const newRewardEndDateInUnixTime = (() => {
    if (+weeklyEmissionInputted === 0) return
    // input the same weekly emission
    if (weeklyEmissionInputted === targetRewardData.weeklyEmissionRate)
      return targetRewardData.runoutTimestamp
    const newEndDate = new Date()
    newEndDate.setSeconds(newEndDate.getSeconds() + extendedSeconds)
    const newEndDateInUnixTime = newEndDate.getTime() / 1000
    return newEndDateInUnixTime
  })()

  const handleEditRate = async () => {
    setIsUpdatingEmission(true)
    const tokenPerSec = (+weeklyEmissionInputted / SECONDS_PER_WEEK).toString()
    await updateBribeRewardRate({
      poolLabel: poolSymbol,
      assetTokenSymbol: assetSymbol,
      rewardTokenSymbol: rewardSymbol,
      tokenPerSec,
      rewarderType: isToShowBribeData ? 'bribeRewarder' : 'boostedPoolRewarder',
    })

    setIsUpdatingEmission(false)
    setWeeklyEmissionInputted('')
  }
  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <h1 className="!mt-0 !mb-3 text-center text-xl font-semibold text-wombatPurple1">
        Edit Emission Rate
      </h1>
      <SelfServiceBribeForm
        guageControl={{
          value: { assetTokenSymbol: assetSymbol, poolLabel: poolSymbol },
          disabled: true,
        }}
        rewardTokenControl={{
          value: rewardSymbol,
          disabled: true,
        }}
        weeklyEmissionControl={{
          value: weeklyEmissionInputted,
          onChange: (value) => {
            setWeeklyEmissionInputted(value)
            const extendedSeconds_ =
              +targetRewardData.remainingSurplus / (+value / SECONDS_PER_WEEK)
            setExtendedSeconds(extendedSeconds_)
          },
          unit: rewardDisplaySymbol,
        }}
        extendedSeconds={extendedSeconds}
      />
      <div className="mt-4 flex flex-col gap-3 text-sm font-medium">
        <div className="flex flex-row justify-between">
          <p>Remaining Reward Surplus:</p>
          <p>
            {targetRewardData ? getCommifiedFormat(targetRewardData.remainingSurplus, 2) : '-'}{' '}
            {rewardDisplaySymbol}
          </p>
        </div>
        <div className="flex flex-row justify-between">
          <p className="font-bold">New {label} End Date:</p>
          <p className="text-gradient">
            {newRewardEndDateInUnixTime
              ? timeConverter(newRewardEndDateInUnixTime, true, false, true)
              : '-'}
          </p>
        </div>
      </div>
      <Button
        className="mt-5 mr-auto ml-auto w-[200px] py-[4px]"
        onClick={handleEditRate}
        isLoading={isUpdatingEmission}
      >
        <span className="text-sm">Edit Rate</span>
      </Button>
    </Modal>
  )
}

export default EditEmissionModal
