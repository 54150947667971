import { TOKENS } from '../../constants/contract'
import { useWeb3 } from '../../context/Web3Context'
import { TokenApprovalState } from '../../hooks/useApproval'
import WButton, { Variant } from '../WButton'

interface ISwapApprovalButton {
  approvalState: TokenApprovalState
  tryApproval: () => Promise<void>
}

export const SwapApprovalButton = ({ approvalState, tryApproval }: ISwapApprovalButton) => {
  const { chainId, isSupported: isOnSupportedChain } = useWeb3()

  const approveHandler = async () => {
    const fromToken = TOKENS[chainId]
    if (!fromToken || !tryApproval) return
    await tryApproval()
  }

  if (!isOnSupportedChain) {
    return (
      <WButton variant={Variant.LIGHT_PURPLE} width="w-full" height="h-11" disabled>
        APPROVE
      </WButton>
    )
  } else if (approvalState === TokenApprovalState.NOT_APPROVED) {
    return (
      <WButton variant={Variant.GRADIENT} width="w-full" height="h-11" onClick={approveHandler}>
        APPROVE
      </WButton>
    )
  } else if (approvalState === TokenApprovalState.LOADING) {
    return <WButton variant={Variant.GRADIENT} width="w-full" height="h-11" isLoading />
  } else {
    return (
      <WButton variant={Variant.LIGHT_PURPLE} width="w-full" height="h-11" disabled>
        APPROVED
      </WButton>
    )
  }
}
