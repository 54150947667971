import Image from 'next/image'
import { useCallback, useMemo, useState } from 'react'
import FormattedNumber from '../../components/FormattedNumber'
import Tooltip from '../../components/Tooltip'
import TooltipNum from '../../components/TooltipNum'
import { TOKENS } from '../../constants/contract'
import { PoolLabels } from '../../constants/contract/pool/PoolLabels'
import { NATIVE_WRAPPED_TOKEN_IN_CHAIN } from '../../constants/contract/token'
import { TokenSymbol } from '../../constants/contract/token/TokenSymbols'
import { SWAP_GAS_ON_DEST_CHAIN_TOOLTIP_MSG } from '../../constants/infoData'
import { chainInfos } from '../../constants/web3/chains'
import { useSwapContext } from '../../context/SwapContext'
import { useWeb3 } from '../../context/Web3Context'
import flip from '../../public/assets/icons/flip-token-horizontal.svg'
import toggleDown from '../../public/assets/icons/toggle-down.svg'
import toggleUp from '../../public/assets/icons/toggle-up.svg'
import { usePoolData } from '../../store/MulticallData/hooks'
import { SwapRoute } from './SwapRoute'

// Note that these values are in percent(%).
function findPoolFees(
  poolFees: { [pool in PoolLabels]?: number },
  poolLabels: PoolLabels[]
): number {
  return poolLabels.reduce((acc, label) => acc + (poolFees[label] || 0), 0)
}

export default function SwapInfo() {
  const [openDropDown, setOpenDropDown] = useState(false)
  const [flipRatio, setFlipRatio] = useState(false)
  const poolData = usePoolData()
  const {
    swapPathData,
    fromTokenSymbol,
    toTokenSymbol,
    priceImpact,
    ratio,
    fee,
    minimumReceive,
    estimatedDeliveryFee,
    isCrossChainSwap,
    sourceChainId,
    targetChainId,
    receiverValue,
  } = useSwapContext()
  console.log({ estimatedDeliveryFee, receiverValue })
  const { chainId } = useWeb3()

  const toggleDropDown = useCallback(() => {
    setOpenDropDown(!openDropDown)
  }, [openDropDown])

  const toggleFlipRatio = useCallback(() => {
    setFlipRatio(!flipRatio)
  }, [flipRatio])

  const fromToken = useMemo(() => {
    return TOKENS[chainId][fromTokenSymbol]
  }, [chainId, fromTokenSymbol])

  const toToken = useMemo(() => {
    return TOKENS[chainId][toTokenSymbol]
  }, [chainId, toTokenSymbol])

  return (
    <div className={'text-wombatDarkPurple pb-2 font-Helvetica'}>
      <div className="flex flex-col">
        <div className="flex items-center justify-end gap-2 p-2 pb-1 text-wombatPurple">
          {/* exchange rate */}
          <div className="flex">
            <button
              className="button-hover-opacity-light flex items-center"
              onClick={toggleFlipRatio}
            >
              {flipRatio ? (
                <>
                  1 {toToken?.displaySymbol}&nbsp;
                  {isCrossChainSwap && <>({chainInfos[targetChainId].label})&nbsp;</>}
                  <Image alt={''} layout="fixed" width={24} height={12} src={flip}></Image>
                  &nbsp;{Number(ratio)?.toFixed(6) ?? '--'}
                  &nbsp;{fromToken?.displaySymbol}
                  {isCrossChainSwap && <>&nbsp;({chainInfos[sourceChainId].label})</>}
                </>
              ) : (
                <>
                  1 {fromToken?.displaySymbol}&nbsp;
                  {isCrossChainSwap && <>({chainInfos[sourceChainId].label})&nbsp;</>}
                  <Image alt={''} layout="fixed" width={24} height={12} src={flip}></Image>
                  &nbsp;{ratio ? (1 / Number(ratio)).toFixed(6) : '--'}
                  &nbsp;{toToken?.displaySymbol}
                  {isCrossChainSwap && <>&nbsp;({chainInfos[targetChainId].label})</>}
                </>
              )}
            </button>
            &nbsp;
            <Tooltip>The rate offered to you for this transaction.</Tooltip>
          </div>
          {/** @TODO Comment out below after update qutation only change input feature */}
          {/* {isQuoting && <Spinner color="#C1ACCA" size={18} />} */}
          {/* drop down button */}
          <button className="button-hover-opacity -m-2 p-2" onClick={() => toggleDropDown()}>
            <Image
              alt={''}
              layout="fixed"
              width={12}
              height={12}
              src={openDropDown ? toggleUp : toggleDown}
            ></Image>
          </button>
        </div>

        {openDropDown && (
          <div className="p-2 outline outline-2 outline-wombatPurple2">
            {/* Minimum Recevied */}
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="mr-1 text-wombatPurple">Minimum Received</div>
                <Tooltip>
                  Your transaction will fail if you&apos;re unable to receive at least this amount.
                  Adjust it in the setting&apos;s slippage tolerance option.
                </Tooltip>
              </div>
              <div className="text-wombatPurple">
                <TooltipNum amount={minimumReceive} />
                &nbsp;{toToken?.displaySymbol}
              </div>
            </div>
            {/* Gas on destination chain */}
            {isCrossChainSwap && (
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="mr-1 text-wombatPurple">Gas On Destination Chain</div>
                  <Tooltip>{SWAP_GAS_ON_DEST_CHAIN_TOOLTIP_MSG}</Tooltip>
                </div>
                <div className="text-wombatPurple">
                  <TooltipNum amount={receiverValue} fixedDigits={5} />
                  &nbsp;
                  {
                    TOKENS[targetChainId][NATIVE_WRAPPED_TOKEN_IN_CHAIN[targetChainId]]
                      ?.displaySymbol as TokenSymbol
                  }
                </div>
              </div>
            )}
            {/* Fee */}
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="mr-1 text-wombatPurple">Fee</div>
                <Tooltip>
                  A{' '}
                  {swapPathData &&
                    findPoolFees(
                      poolData.withoutAccount?.poolFeePercentages || {},
                      swapPathData.poolLabelPath
                    ).toFixed(3)}
                  % transaction fee is charged. This fee is already included.
                </Tooltip>
              </div>
              <span className="text-wombatPurple">
                <TooltipNum fixedDigits={5} amount={fee} millified />
                {/** Fee in terms of fromToken when doing cross chain swap */}
                &nbsp;{isCrossChainSwap ? fromToken?.displaySymbol : toToken?.displaySymbol}
              </span>
            </div>
            {/* Estimate Delivery fee */}
            {isCrossChainSwap && (
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="mr-1 text-wombatPurple">Estimated Delivery Fee</div>
                </div>
                <span className="text-wombatPurple">
                  <TooltipNum fixedDigits={5} amount={estimatedDeliveryFee || 0} />
                  &nbsp;
                  {/** @Caveat We didn't add a token config for avax fuji in TOKENS. That's why no native token is shown on UI */}
                  {
                    TOKENS[sourceChainId][NATIVE_WRAPPED_TOKEN_IN_CHAIN[sourceChainId]]
                      ?.displaySymbol
                  }
                </span>
              </div>
            )}
            {/* Price Impact */}
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="mr-1 text-wombatPurple">Price Impact</div>
                <Tooltip>The difference between the market price and the ideal price.</Tooltip>
              </div>
              <span
                className={
                  priceImpact && Number(priceImpact) > 0.01 ? 'text-red-500' : 'text-wombatPurple'
                }
              >
                <FormattedNumber
                  amount={Number(priceImpact) ?? 0}
                  threshold={0.001}
                  showColor={false}
                ></FormattedNumber>
              </span>
            </div>
            {/* Route */}
            <div className="flex items-center justify-between">
              <div className="mr-1 text-wombatPurple">Route</div>
              {swapPathData && !isCrossChainSwap && (
                <SwapRoute
                  tokenRoute={swapPathData.tokenSymbolPath.map((tokenSymbol) => ({
                    tokenSymbol,
                    chainId,
                  }))}
                />
              )}
              {isCrossChainSwap && (
                <SwapRoute
                  tokenRoute={[
                    { tokenSymbol: fromTokenSymbol, chainId: sourceChainId },
                    { tokenSymbol: toTokenSymbol, chainId: targetChainId },
                  ]}
                  enableChainLabel
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
