import { AssetConfig } from '..'

import { PoolLabels } from '../../../pool/PoolLabels'
import { TokenSymbols } from '../../../token/TokenSymbols'
import { defaultAssetConfig } from '../defaultAssetConfig'

export const BSC_MAINNET_ASSET_CONFIG: AssetConfig = {
  ...defaultAssetConfig,
  [PoolLabels.CROSS_CHAIN]: {
    [TokenSymbols.USDC]: {
      name: 'USD Coin',
      color: '#000000',
      hasBribeV2: true,
    },
    [TokenSymbols.USDT]: {
      name: 'Tether USD',
      color: '#000000',
      hasBribeV2: true,
    },
  },
  [PoolLabels.FACTORY_STABLES]: {
    [TokenSymbols.FRAX]: {
      name: 'Frax',
      color: '#000000',
    },
  },
  [PoolLabels.MAIN]: {
    [TokenSymbols.USDC]: {
      name: 'USD Coin',
      color: '#1D72B9',
    },
    [TokenSymbols.USDT]: {
      name: 'Tether USD',
      color: '#22B093',
    },
    [TokenSymbols.DAI]: {
      name: 'Dai Stablecoin',
      color: '#FFB118',
      displaySymbol: 'DAI',
    },
  },
  [PoolLabels.SIDE]: {
    [TokenSymbols.lisUSD]: {
      name: 'lisUSD',
      color: '#E38E2A',
    },
    [TokenSymbols.USDC]: {
      name: 'USDC',
      color: '#F7BD67',
    },
    [TokenSymbols.USDT]: {
      name: 'USDT',
      color: '#F7A467',
    },
  },
  [PoolLabels.qWOM]: {
    [TokenSymbols.WOM]: {
      name: 'Wombat Token',
      color: '#C6F4A2',
    },
    [TokenSymbols.qWOM]: {
      name: 'Quoll WOM',
      color: '#00F078',
    },
  },
  [PoolLabels.mWOM]: {
    [TokenSymbols.WOM]: {
      name: 'Wombat Token',
      color: '#5DA6F8',
    },
    [TokenSymbols.mWOM]: {
      name: 'mWOM',
      color: '#A8D1FF',
    },
  },
  [PoolLabels.wmxWOM]: {
    [TokenSymbols.WOM]: {
      name: 'Wombat Token',
      color: '#9D561C',
    },
    [TokenSymbols.wmxWOM]: {
      name: 'Wombex WOM',
      color: '#685D54',
      displaySymbol: 'wmxWOM',
    },
    /** @todo the token name on mainnet is called wmxWom but not wmxWOM */
    [TokenSymbols.wmxWom]: {
      name: 'Wombex WOM',
      color: '#685D54',
      displaySymbol: 'wmxWOM',
    },
  },
  [PoolLabels.BNBx]: {
    [TokenSymbols.WBNB]: {
      name: 'BNB',
      color: '#F0B90B',
      displaySymbol: 'BNB', // display as BNB
    },
    [TokenSymbols.BNBx]: {
      name: 'Stader BNB',
      color: '#755E21',
      interestBearingAprEndpoint: 'https://universe.staderlabs.com/bnb/apy',
    },
  },
  [PoolLabels.stkBNB]: {
    [TokenSymbols.WBNB]: {
      name: 'BNB',
      color: '#F08E6F',
      displaySymbol: 'BNB', // display as BNB
    },
    [TokenSymbols.stkBNB]: {
      name: 'pSTAKE Staked BNB',
      color: '#B53F3D',
    },
  },
  [PoolLabels.axlUSDC]: {
    [TokenSymbols.BUSD]: {
      name: 'Binance USD',
      color: '#2F455B',
    },
    [TokenSymbols.axlUSDC]: {
      name: 'Axelar Wrapped USDC',
      color: '#40648A',
    },
  },
  [PoolLabels.iUSD]: {
    [TokenSymbols.BUSD]: {
      name: 'Binance USD',
      color: '#4D719B',
    },
    [TokenSymbols.iUSD]: {
      name: 'iZUMi Bond USD',
      color: '#2A60F1',
    },
  },
  [PoolLabels.USDD]: {
    [TokenSymbols.USDC]: {
      name: 'USD Coin',
      color: '#0E9189',
    },
    [TokenSymbols.USDD]: {
      name: 'Decentralized USD',
      color: '#216C58',
    },
  },
  [PoolLabels.BOB]: {
    [TokenSymbols.USDC]: {
      name: 'USD Coin',
      color: '#F6AAAE',
    },
    [TokenSymbols.BOB]: {
      name: 'zkBob',
      color: '#EB73D9',
    },
  },
  [PoolLabels.frxETH]: {
    [TokenSymbols.frxETH]: {
      name: 'Frax Ether',
      enableVoting: true,
      color: '#B3235C',
    },
    [TokenSymbols.WETH]: {
      name: 'Wrapped Ether',
      enableVoting: true,
      paused: true,
      color: '#FF258C',
      displaySymbol: 'WETH (deprecated)',
    },
    [TokenSymbols.ETH]: {
      name: 'Binance-peg Ethereum Token',
      enableVoting: true,
      color: '#FF258C',
    },
    [TokenSymbols.sfrxETH]: {
      name: 'Frax Staked Ether',
      color: '#FF7BB9',
    },
  },
  [PoolLabels.Overnight]: {
    [TokenSymbols['USD+']]: {
      name: 'USD+',
      enableVoting: true,
      color: '#3886D7',
      displaySymbol: 'USD+',
    },
    [TokenSymbols['USDT+']]: {
      name: 'USDT+',
      enableVoting: true,
      color: '#023F86',
      displaySymbol: 'USDT+',
    },
    [TokenSymbols.USDC]: {
      name: 'USDC',
      enableVoting: true,
      color: '#001845',
    },
    [TokenSymbols.FRAX]: {
      name: 'FRAX',
      color: '#0F0F0E',
    },
  },
  [PoolLabels.MIM]: {
    [TokenSymbols.MIM]: {
      name: 'MIM',
      color: '#5552FD',
    },
    [TokenSymbols.USDT]: {
      name: 'USDT',
      color: '#9695F8',
    },
  },
  [PoolLabels.ankrBNB]: {
    [TokenSymbols.WBNB]: {
      name: 'BNB',
      color: '#D1B000',
      displaySymbol: 'BNB', // display as BNB
    },
    [TokenSymbols.ankrBNB]: {
      name: 'ankrBNB',
      color: '#FAE853',
    },
  },
  [PoolLabels.BNBy]: {
    [TokenSymbols.WBNB]: {
      name: 'BNB',
      color: '#BE383D',
      displaySymbol: 'BNB', // display as BNB
    },
    [TokenSymbols.BNBy]: {
      name: 'BNBy',
      color: '#FD4530',
    },
  },
  [PoolLabels.wBETH]: {
    [TokenSymbols.wBETH]: {
      name: 'wBETH',
      color: '#F8D137',
    },
    [TokenSymbols.ETH]: {
      name: 'ETH',
      color: '#F2BE14',
    },
  },
  [PoolLabels.ankrETH]: {
    [TokenSymbols.ankrETH]: {
      name: 'ankrETH',
      color: '#FFE81C',
    },
    [TokenSymbols.ETH]: {
      name: 'ETH',
      color: '#8C800F',
    },
  },
  [PoolLabels.slisBNB]: {
    [TokenSymbols.slisBNB]: {
      name: 'SnBNB',
      color: '#BFB2FF',
    },
    [TokenSymbols.WBNB]: {
      name: 'BNB',
      color: '#8274AA',
      displaySymbol: 'BNB', // display as BNB
    },
  },
  [PoolLabels.rBNB]: {
    [TokenSymbols.rBNB]: {
      name: 'rBNB',
      color: '#59C3C9',
    },
    [TokenSymbols.WBNB]: {
      name: 'BNB',
      color: '#64D9BC',
      displaySymbol: 'BNB', // display as BNB
    },
  },
  [PoolLabels.USDS]: {
    [TokenSymbols.USDS]: {
      name: 'USDS',
      color: '#D4D4D4',
    },
    [TokenSymbols.USDT]: {
      name: 'USDT',
      color: '#767676',
    },
  },
  [PoolLabels.zBNB]: {
    [TokenSymbols.zBNB]: {
      name: 'zBNB',
      color: '#2AD4B7',
      hasBribeV2: true,
    },
    [TokenSymbols.WBNB]: {
      name: 'BNB',
      color: '#F3BA2F',
      displaySymbol: 'BNB', // display as BNB
      hasBribeV2: true,
    },
  },
  [PoolLabels.zUSD]: {
    [TokenSymbols.zUSD]: {
      name: 'zUSD',
      color: '#3377FF',
      hasBribeV2: true,
    },
    [TokenSymbols.USDC]: {
      name: 'USDC',
      color: '#F7F9FE',
      hasBribeV2: true,
    },
  },
  [PoolLabels.USDV]: {
    [TokenSymbols.USDV]: {
      name: 'USDV',
      color: '#D4D4D4',
    },
    [TokenSymbols.USDT]: {
      name: 'USDT',
      color: '#767676',
    },
  },
}
