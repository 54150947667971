import { strToWad } from '@hailstonelabs/big-number-utils'
import React, { useCallback } from 'react'
import { TokenApprovalState } from '../../hooks/useApproval'
import WButton, { Variant } from '../WButton'
import { useWeb3 } from '../../context/Web3Context'
import { useSwapContext } from '../../context/SwapContext'
import { useSwapInputContext } from '../../context/SwapInputContext'
import { useSwap } from '../../hooks/swap/useSwap'
import { useModalContext } from '../../context/ModalContext'
import { ModalId } from '../../interfaces/modal'
import { HexString } from '../../interfaces/contract'
import { CROSS_CHAIN_GAS_RECEIVING_AMOUNT } from '../../constants/web3/chains'
import { useCrossChainSwap } from '../../hooks/swap/useCrossChainSwap'

interface SwapButtonProps {
  approvalState: TokenApprovalState
}

export const SwapButton: React.FC<SwapButtonProps> = ({ approvalState }) => {
  const { isSupported: isOnSupportedChain } = useWeb3()
  const {
    fromTokenAmount,
    toTokenAmount,
    isCrossChainSwap,
    targetChainId,
    receiverValue,
    isQuotingSwapInfo,
  } = useSwapContext()
  const {
    fromTokenAmountGtBalance,
    isEndCovRatioExceed,
    displayInvalidAddressWarning,
    recipientAddress,
  } = useSwapInputContext()

  const { handleSwap } = useSwap()
  const { handleCrossChainSwap } = useCrossChainSwap()
  const { modalState } = useModalContext()

  const isGasLimitExceed = isCrossChainSwap
    ? strToWad(receiverValue).gt(
        strToWad(CROSS_CHAIN_GAS_RECEIVING_AMOUNT[targetChainId].maxAmount)
      )
    : false

  // swap button states
  const isLoading = modalState.currentModalId === ModalId.SWAP_WAIT_FOR_CONFIRMATION
  const isDisabled =
    approvalState !== TokenApprovalState.APPROVED ||
    fromTokenAmountGtBalance ||
    !fromTokenAmount ||
    !isOnSupportedChain ||
    isEndCovRatioExceed ||
    displayInvalidAddressWarning ||
    isGasLimitExceed

  const isEnabled = !isLoading && !isDisabled && !isQuotingSwapInfo

  const handleSwapButtonOnClick = useCallback(async () => {
    if (strToWad(fromTokenAmount).isZero() || strToWad(toTokenAmount).isZero() || !isEnabled) return
    if (isCrossChainSwap) {
      await handleCrossChainSwap()
    } else {
      await handleSwap(recipientAddress as HexString)
    }
  }, [
    fromTokenAmount,
    toTokenAmount,
    isEnabled,
    isCrossChainSwap,
    handleCrossChainSwap,
    handleSwap,
    recipientAddress,
  ])

  if (isDisabled || isQuotingSwapInfo) {
    return (
      <WButton variant={Variant.LIGHT_PURPLE} width="w-full" height="h-11" disabled>
        SWAP
      </WButton>
    )
  } else if (isLoading) {
    return <WButton variant={Variant.GRADIENT} width="w-full" height="h-11" disabled isLoading />
  } else {
    return (
      <WButton
        variant={Variant.GRADIENT}
        width="w-full"
        height="h-11"
        disabled={!isEnabled}
        onClick={handleSwapButtonOnClick}
      >
        SWAP
      </WButton>
    )
  }
}
