import { PoolConfig } from '..'
import { PoolLabels } from '../../PoolLabels'
import { PoolFilterGroup } from '../../poolFilterGroup'

/** Pool page follows this order  */
export const SCROLL_MAINNET_POOL_CONFIG: PoolConfig = {
  /** @todo switch back to Crosschain pool when we enable crosschain swap */
  [PoolLabels.CROSS_CHAIN]: {
    name: 'Cross-Chain Pool',
    // name: 'Main Pool',
    filterGroup: PoolFilterGroup.CROSS_CHAIN,
    address: '0x80f088ae72DB6d1AC337340cd6Aa0EB1F67337CE',
  },
}
