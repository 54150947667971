import { AssetConfig } from '..'
import { PoolLabels } from '../../../pool/PoolLabels'
import { TokenSymbols } from '../../../token/TokenSymbols'
import { defaultAssetConfig } from '../defaultAssetConfig'

/** @todo should not inlcude unnecessary pool labels */
export const ARB1_ASSET_CONFIG: AssetConfig = {
  ...defaultAssetConfig,
  [PoolLabels.MAIN]: {
    [TokenSymbols['USDC.e']]: {
      name: 'USD Coin',
      color: '#1D72B9',
    },
    [TokenSymbols.USDC]: {
      name: 'USD Coin',
      /**@todo update the color */
      color: '#F8B900',
    },
    [TokenSymbols.USDT]: {
      name: 'Tether USD',
      color: '#22B093',
    },
    [TokenSymbols.DAI]: {
      name: 'Dai Stablecoin',
      color: '#FFB118',
    },
  },
  [PoolLabels.qWOM]: {
    [TokenSymbols.WOM]: {
      name: 'Wombat Token',
      color: '#445633',
      enableVoting: true,
    },
    [TokenSymbols.qWOM]: {
      name: 'Quoll WOM',
      color: '#97CAB1',
      enableVoting: true,
    },
  },
  [PoolLabels.mWOM]: {
    [TokenSymbols.WOM]: {
      name: 'Wombat Token',
      color: '#859CB6',
      enableVoting: true,
    },
    [TokenSymbols.mWOM]: {
      name: 'mWOM',
      color: '#657A92',
      enableVoting: true,
    },
  },
  [PoolLabels.wmxWOM]: {
    [TokenSymbols.WOM]: {
      name: 'Wombat Token',
      color: '#BEAB9B',
      enableVoting: true,
    },
    [TokenSymbols.wmxWOM]: {
      name: 'Wombex WOM',
      color: '#704929',
      enableVoting: true,
    },
  },
  [PoolLabels.BOB]: {
    [TokenSymbols.BOB]: {
      name: 'BOB',
      color: '#C8A2CB',
      enableVoting: true,
    },
    [TokenSymbols.USDC]: {
      name: 'USDC',
      color: '#CC8185',
      enableVoting: true,
    },
  },
  [PoolLabels.frxETH]: {
    [TokenSymbols.frxETH]: {
      name: 'Frax Ether',
      color: '#C2A2FF',
      enableVoting: true,
    },
    [TokenSymbols.WETH]: {
      name: 'ETH',
      color: '#E5C5FF',
      enableVoting: true,
      displaySymbol: 'ETH',
    },
    [TokenSymbols.sfrxETH]: {
      name: 'Frax Staked Ether',
      color: '#A88FBD',
      enableVoting: true,
    },
  },
  [PoolLabels.Overnight]: {
    [TokenSymbols['USD+']]: {
      name: 'USD+',
      color: '#BFD2E6',
      enableVoting: true,
      displaySymbol: 'USD+',
    },
    [TokenSymbols['DAI+']]: {
      name: 'DAI+',
      color: '#DEE6EF',
      enableVoting: true,
      displaySymbol: 'DAI+',
    },
    [TokenSymbols.USDC]: {
      name: 'USDC',
      color: '#BDC3CE',
      enableVoting: true,
    },
  },
  [PoolLabels.MIM]: {
    [TokenSymbols.MIM]: {
      name: 'MIM',
      color: '#9B9BCF',
      enableVoting: true,
    },
    [TokenSymbols.USDT]: {
      name: 'USDT',
      color: '#6B6AC1',
      enableVoting: true,
    },
  },
  [PoolLabels.FRAX_MAI_USDplus]: {
    [TokenSymbols.FRAX]: {
      name: 'FRAX',
      color: '#373131',
      enableVoting: true,
    },
    [TokenSymbols['USD+']]: {
      name: 'USD+',
      color: '#23DD00',
      enableVoting: true,
      displaySymbol: 'USD+',
    },
    [TokenSymbols.USDC]: {
      name: 'USDC',
      color: '#426A98',
      enableVoting: true,
    },
  },
  [PoolLabels.FRAX_USDV]: {
    [TokenSymbols.FRAX]: {
      name: 'FRAX',
      color: '#373131',
      enableVoting: true,
      hasBribeV2: true,
    },
    [TokenSymbols.USDV]: {
      name: 'USDV',
      color: '#D4D4D4',
      enableVoting: true,
      hasBribeV2: true,
    },
  },
  [PoolLabels.FRAX_sFRAX_USDC]: {
    [TokenSymbols.FRAX]: {
      name: 'FRAX',
      color: '#373131',
    },
    [TokenSymbols.USDC]: {
      name: 'USDC',
      color: '#D4D4D4',
    },
    [TokenSymbols.sFRAX]: {
      name: 'sFRAX',
      color: '#D4D4D4',
    },
  },
  [PoolLabels.jUSDC]: {
    [TokenSymbols.jUSDC]: {
      name: 'jUSDC',
      color: '#F98003',
    },
    [TokenSymbols.USDC]: {
      name: 'USDC',
      color: '#FFCE9B',
    },
  },
  [PoolLabels.ankrETH]: {
    [TokenSymbols.ankrETH]: {
      name: 'ankrETH',
      color: '#F7EEA4',
    },
    [TokenSymbols.WETH]: {
      name: 'WETH',
      color: '#E2DEBA',
      displaySymbol: 'ETH',
    },
  },
  [PoolLabels.wstETH]: {
    [TokenSymbols.wstETH]: {
      name: 'wstETH',
      color: '#71DEFF',
    },
    [TokenSymbols.WETH]: {
      name: 'WETH',
      color: '#F0FFAA',
      displaySymbol: 'ETH',
    },
  },
  [PoolLabels.fUSDC]: {
    [TokenSymbols.fUSDC]: {
      name: 'Fluid USDC',
      color: '#AEA0E3',
      enableVoting: false,
    },
    [TokenSymbols['USDC.e']]: {
      name: 'USD Coin',
      color: '#7C7AF6',
      enableVoting: false,
    },
  },
  [PoolLabels.mPendle]: {
    [TokenSymbols.PENDLE]: {
      name: 'PENDLE',
      color: '#99A9CC',
    },
    [TokenSymbols.mPendle]: {
      name: 'mPendle',
      color: '#A9F5F5',
    },
  },
  [PoolLabels.CROSS_CHAIN]: {
    [TokenSymbols.USDC]: {
      name: 'USD Coin',
      color: '#000000',
      hasBribeV2: true,
    },
    [TokenSymbols.USDT]: {
      name: 'Tether USD',
      color: '#000000',
      hasBribeV2: true,
    },
  },
  [PoolLabels.USDV]: {
    [TokenSymbols.USDV]: {
      name: 'USDV',
      color: '#D4D4D4',
    },
    [TokenSymbols.USDT]: {
      name: 'USDT',
      color: '#767676',
    },
  },
}
