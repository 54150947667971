import clsx from 'clsx'
import Image from 'next/image'
import { FC, ReactNode } from 'react'
import LoadingIcon from '../../public/assets/icons/loading.svg'

export const enum Variant {
  GRADIENT,
  PURPLE,
  BLACK,
  LIGHT_PURPLE,
  WHITE,
  RED,
  YELLOW,
}

const renderVarirant = (variant: Variant) => {
  switch (variant) {
    case Variant.GRADIENT:
      return 'bg-linearGradientApprove'
    case Variant.PURPLE:
      return 'bg-wombatPurple1'
    case Variant.BLACK:
      return 'bg-wombatGrey'
    case Variant.LIGHT_PURPLE:
      return 'bg-wombatPurple3'
    case Variant.WHITE:
      return 'bg-white'
    case Variant.RED:
      return 'bg-wombatRed'
    case Variant.YELLOW:
      return 'bg-wombatYellow2'
    default:
      break
  }
}

const renderCursor = (disabled: boolean, isLoading: boolean) => {
  if (disabled) return 'cursor-not-allowed'
  if (isLoading) return 'cursor-progress'
  return 'button-hover-opacity-light'
}

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  variant: Variant
  children?: ReactNode
  isLoading?: boolean
  disabled?: boolean
  height?: string
  width?: string
  labelClassName?: string
  enableDimmed?: boolean
}

const WButton: FC<Props> = ({
  variant,
  isLoading = false,
  width,
  height,
  disabled = false,
  className,
  children,
  labelClassName,
  enableDimmed = false,
  ...rest
}) => {
  return (
    <button
      disabled={disabled}
      className={`relative flex items-center justify-center ${width ? width : 'w-32 md:w-40'}  ${
        height ? height : 'h-8 md:h-10'
      } ${className} ${renderVarirant(variant)} ${renderCursor(disabled, isLoading)} ${
        disabled && enableDimmed && 'opacity-50'
      }`}
      {...rest}
    >
      {isLoading ? (
        <div className="h-6 w-6 animate-spin">
          <Image alt={''} src={LoadingIcon} width={32} height={32} />
        </div>
      ) : (
        <span
          className={clsx(
            'font-Jamjuree text-base font-bold text-wombatCream',
            labelClassName && labelClassName
          )}
        >
          {children}
        </span>
      )}
    </button>
  )
}
export default WButton
