import { getDpFormat, getSfFormat } from '@hailstonelabs/big-number-utils'
import { BigNumber, ContractTransaction, utils } from 'ethers'
import Image from 'next/image'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Asset } from '../../../constants/contract/asset/Asset'
import { Token } from '../../../constants/contract/token/Token'
import { ROUTERS } from '../../../constants/contract/router'
import { ErrorMessages, PROVIDER_ERROR_CODES } from '../../../context/errorMessage'
// import { useMigrationDetectionContext } from '../../../context/MigrationDetectionContext'
import { useWeb3 } from '../../../context/Web3Context'
import { useUserPreference } from '../../../context/userPreferenceContext'
import useApproval, { TokenApprovalState } from '../../../hooks/useApproval'
import warnning from '../../../public/assets/icons/warning-icon.svg'
import { calculateGasMargin } from '../../../utils'
import { formatNumberUSLocale, roundPercentage } from '../../../utils/numberFormat'
import { getMinimumReceived } from '../../../utils/pool'
import { delay, getDeadlineFromNow } from '../../../utils/utils'
import TokenImage from '../../TokenImage'
import Tooltip from '../../Tooltip'
import TransactionFailedModal from '../../TransactionFailedModal'
import WithdrawSubmitedModal from '../../TransactionSubmittedModal'
import WButton, { Variant } from '../../WButton'
import WithdrawConfirmModal from '../../WithdrawConfirmModal'
import { MODAL_STATE } from '../WithdrawalPageContext'
import { Pool } from '../../../constants/contract/pool/Pool'
import { useContract } from 'wagmi'
import { NATIVE_WRAPPED_TOKEN_IN_CHAIN } from '../../../constants/contract/token'
import { StandalonePool } from '../../../constants/contract/standalonePool'
import { PoolLabels } from '../../../constants/contract/pool/PoolLabels'
import { useAppDispatch } from '../../../store/hooks'
import { addErrorToast, addSuccessToast } from '../../../store/Toast/actions'
import { useDelistedData } from '../../../store/MigrationDetection/hooks'
import updateAllDelistedData from '../../../store/MigrationDetection/thunks/updateAllDelistedData'

enum WithdrawState {
  IDLE,
  LOADING,
  SUCCESS,
  FAILED,
  SUBMITED,
  UNKNOWN,
}

interface Props {
  redirect: (value: MODAL_STATE | null) => void
  poolLabel: PoolLabels
  poolForOperation: Pool | StandalonePool
  asset: Asset
  token: Token
}

export default function Withdraw({ poolLabel, poolForOperation, asset, token }: Props) {
  const { chainId, account, signer } = useWeb3()
  // const { delistedData, updateAllDelistedData } = useMigrationDetectionContext()
  const dispatch = useAppDispatch()
  const delistedData = useDelistedData()
  const stakableLpAmountBN = useMemo(
    () =>
      utils.parseUnits(
        delistedData[poolLabel]?.[asset.symbol]?.lpAmountInTermsOfLp.stakable || '0',
        asset.decimals
      ),
    [asset.decimals, asset.symbol, delistedData, poolLabel]
  )
  const [txHash, setTxHash] = useState<boolean>(false)
  const [transactionHash, setTransactionHash] = useState<string>('')
  const [withdrawState, setWithdrawState] = useState<WithdrawState>(WithdrawState.IDLE)
  const [isDisplayModal, setIsDisplayModal] = useState<boolean>(true)
  const { userPreference } = useUserPreference()
  const poolContract = useContract({
    ...poolForOperation.get(),
    signerOrProvider: signer,
  })

  const routerContract = useContract({
    ...ROUTERS[chainId]?.get(),
    signerOrProvider: signer,
  })
  const isWithdrawInNative = token.symbol === NATIVE_WRAPPED_TOKEN_IN_CHAIN[chainId]
  const { approvalState, tryApproval } = useApproval(
    asset.address,
    isWithdrawInNative ? routerContract?.address ?? null : poolForOperation.address,
    stakableLpAmountBN
  )
  const [withdrawData, setWithdrawData] = useState<{
    miniumReceived: string
    tokenReceived: string
    fee: string
  }>({
    miniumReceived: '0.0',
    tokenReceived: '0.0',
    fee: '0.0',
  })
  const {
    userPreference: { slippage },
  } = useUserPreference()
  const hasDeposit = stakableLpAmountBN.gt('0')
  useEffect(() => {
    if (!isDisplayModal && withdrawState !== WithdrawState.SUBMITED) {
      setWithdrawState(WithdrawState.IDLE)
      setIsDisplayModal(true)
    }
  }, [isDisplayModal, withdrawState])
  useEffect(() => {
    if (poolContract) {
      if (hasDeposit) {
        const quotes = poolContract.quotePotentialWithdraw(token.address, stakableLpAmountBN)
        quotes
          .then(({ amount, fee }) => {
            setWithdrawData({
              miniumReceived: utils.formatUnits(
                getMinimumReceived(amount, slippage),
                token.decimals
              ),
              tokenReceived: utils.formatUnits(amount, token.decimals),
              fee: utils.formatUnits(fee, token.decimals),
            })
          })
          .catch((e) => {
            console.log(e)
          })
      } else {
        setWithdrawData({
          miniumReceived: '0.0',
          tokenReceived: '0.0',
          fee: '0.0',
        })
      }
    }
  }, [hasDeposit, poolContract, slippage, stakableLpAmountBN, token.address, token.decimals])
  // withdraw
  const withdraw = useCallback(async () => {
    if (!routerContract) throw 'cannot get router'
    if (!(token && poolContract && account)) {
      return
    }
    try {
      setWithdrawState(WithdrawState.LOADING)
      const deadline = getDeadlineFromNow(userPreference.transactionDeadline)
      let txn: ContractTransaction
      if (token.symbol === NATIVE_WRAPPED_TOKEN_IN_CHAIN[chainId]) {
        // unwrap WBNB
        txn = await routerContract.removeLiquidityNative(
          poolContract.address,
          stakableLpAmountBN,
          utils.parseUnits(withdrawData.miniumReceived, token.decimals),
          account,
          BigNumber.from(deadline)
        )
      } else {
        const estimateGas = await poolContract.estimateGas.withdraw(
          token.address,
          stakableLpAmountBN,
          utils.parseUnits(withdrawData.miniumReceived, token.decimals),
          account,
          BigNumber.from(deadline)
        )
        txn = await poolContract.withdraw(
          token.address,
          stakableLpAmountBN,
          utils.parseUnits(withdrawData.miniumReceived, token.decimals),
          account,
          BigNumber.from(deadline),
          {
            gasLimit: calculateGasMargin(estimateGas),
          }
        )
      }
      setTransactionHash(txn.hash)
      setTxHash(true)
      await delay(1500)
      setWithdrawState(WithdrawState.SUBMITED)
      await txn.wait()
      setWithdrawState(WithdrawState.SUCCESS)
      dispatch(
        addSuccessToast({
          message: `${withdrawData.tokenReceived} ${token.displaySymbol}`,
          title: 'Withdraw completed',
          txHash: txn.hash,
        })
      )
      setIsDisplayModal(true)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const code = PROVIDER_ERROR_CODES.REQUEST_DENIED_ERROR
      if (JSON.stringify(error).includes(code)) {
        setWithdrawState(WithdrawState.IDLE)
        const errorMessage = ErrorMessages[code]
        dispatch(
          addErrorToast({
            message: errorMessage.message,
            title: errorMessage.title,
          })
        )
      } else {
        setIsDisplayModal(true)
        setWithdrawState(WithdrawState.FAILED)
        dispatch(
          addErrorToast({
            message: `${formatNumberUSLocale(withdrawData.tokenReceived, 2)} ${
              token.displaySymbol
            }`,
            title: 'Withdraw Failed',
            txHash: transactionHash,
          })
        )
      }
    } finally {
      dispatch(
        updateAllDelistedData({
          chainId,
          account,
        })
      )
      setTxHash(false)
      setIsDisplayModal(true)
    }
  }, [
    routerContract,
    token,
    poolContract,
    account,
    userPreference.transactionDeadline,
    chainId,
    dispatch,
    withdrawData.tokenReceived,
    withdrawData.miniumReceived,
    stakableLpAmountBN,
    transactionHash,
  ])
  const renderModal = () => {
    switch (withdrawState) {
      case WithdrawState.LOADING:
        return (
          <WithdrawConfirmModal
            isOpen
            tokenAmount={stakableLpAmountBN}
            txHash={txHash}
            tokenFrom={token}
            tokenTo={token}
            miniumReceived={Number(withdrawData.miniumReceived)}
            onClose={() => setIsDisplayModal(false)}
          />
        )
      case WithdrawState.SUBMITED:
        return (
          <WithdrawSubmitedModal
            isAddTokenToWallet={false}
            hash={transactionHash}
            isOpen
            onClose={() => setIsDisplayModal(false)}
            chainId={chainId}
          />
        )
      case WithdrawState.FAILED:
        return <TransactionFailedModal isOpen onClose={() => setIsDisplayModal(false)} />
    }
  }

  const buttonGroup = () => {
    switch (approvalState) {
      case TokenApprovalState.UNKNOWN:
      case TokenApprovalState.NETWORK_UNSUPPORTED:
        return (
          <div className="flex flex-row items-center justify-between">
            <WButton variant={Variant.LIGHT_PURPLE} width="w-full" disabled>
              APPROVE
            </WButton>
            <div className="h-0.5 w-8 flex-none border-t-1 border-wombatPurple3"></div>
            <div className="relative w-full">
              <WButton variant={Variant.LIGHT_PURPLE} width="w-full" disabled>
                WITHDRAW
              </WButton>
            </div>
          </div>
        )
      case TokenApprovalState.LOADING:
        return (
          <div className="flex flex-row items-center text-lg">
            <WButton variant={Variant.GRADIENT} width="w-full" isLoading={true} />
            <div className="h-0.5 w-8 flex-none border-t-1 border-wombatPurple3"></div>
            <div className="relative w-full">
              <WButton variant={Variant.LIGHT_PURPLE} width="w-full" disabled>
                WITHDRAW
              </WButton>
            </div>
          </div>
        )
      case TokenApprovalState.NOT_APPROVED:
        return (
          <div className="flex flex-row items-center text-lg">
            {token ? (
              <WButton
                variant={Variant.GRADIENT}
                className={`withdraw-${token.symbol}-approve`}
                width="w-full"
                onClick={() => tryApproval()}
              >
                APPROVE
              </WButton>
            ) : (
              <WButton variant={Variant.LIGHT_PURPLE} width="w-full">
                APPROVE
              </WButton>
            )}
            <div className="h-0.5 w-8 flex-none border-t-1 border-wombatPurple3"></div>
            <div className="relative w-full">
              <WButton variant={Variant.LIGHT_PURPLE} width="w-full" disabled>
                WITHDRAW
              </WButton>
            </div>
          </div>
        )

      case TokenApprovalState.APPROVED:
        return (
          <div className="flex flex-row items-center text-lg">
            <WButton
              disabled
              width="w-full"
              variant={Variant.LIGHT_PURPLE}
              className={`withdraw-approved-${asset.symbol}`}
            >
              APPROVED
            </WButton>
            <div className="h-0.5 w-8 flex-none border-t-1 border-wombatPurple3"></div>
            <div className="relative w-full">
              <WButton
                className={`withdraw-${asset.symbol}-withdraw`}
                variant={
                  !stakableLpAmountBN || stakableLpAmountBN?.eq(0)
                    ? Variant.LIGHT_PURPLE
                    : Variant.GRADIENT
                }
                width="w-full"
                disabled={!stakableLpAmountBN || stakableLpAmountBN?.eq(0)}
                isLoading={
                  withdrawState === WithdrawState.LOADING ||
                  withdrawState === WithdrawState.SUBMITED
                }
                onClick={async () => {
                  withdraw()
                }}
              >
                {withdrawState !== WithdrawState.LOADING &&
                  withdrawState !== WithdrawState.SUBMITED &&
                  'WITHDRAW'}
              </WButton>
            </div>
          </div>
        )
    }
  }

  return (
    <>
      {asset && (
        <div className="w-full rounded-lg bg-wombatCream p-3 font-Work">
          <div className="flex items-center gap-2 pb-2">
            <div className="h-[34px]">
              <TokenImage tokenSymbol={asset.symbol} width="34" height="34" />
            </div>
            <div className="flex w-full items-center justify-between">
              <div className="flex select-none items-center pl-1">
                <div className="flex-row">
                  <div className="font-semibold leading-5">{asset.symbol}</div>
                  <div className="font-Work text-xs leading-3 text-wombatBrown1">{asset.name}</div>
                </div>
              </div>
            </div>
            <div className="flex h-8 w-full  items-center justify-between rounded-md  bg-wombatCream px-2 font-Work font-semibold text-wombatBrown1 ">
              {getSfFormat(utils.formatEther(stakableLpAmountBN), 2)}
            </div>
          </div>
        </div>
      )}
      <div className="mt-2 flex justify-between">
        <div className="flex items-center">
          <span>Fee&nbsp;</span>{' '}
          <Tooltip pointerEvent={true} isTextPositionRightOnMobile={true}>
            Penalty to diverge the coverage ratio of the token.{' '}
            <a
              href="https://docs.wombat.exchange/concepts/fees/deposit-gain-and-withdrawal-fee"
              target="_blank"
              className="font-bold hover:underline"
              rel="noreferrer"
            >
              Learn More
            </a>
          </Tooltip>
        </div>
        <div>
          {getDpFormat(withdrawData.fee, 2, 'down', true)}
          {token?.displaySymbol}
        </div>
      </div>
      <div className="mt-2 flex justify-between">
        <div className="flex items-center">
          <span>Max Receive&nbsp;</span>{' '}
        </div>
        <div>
          {getDpFormat(withdrawData.tokenReceived, 2, 'down', true)}
          {token?.displaySymbol}
        </div>
      </div>
      <div className="py-4 font-Work text-sm font-normal text-wombatBrown">
        {!hasDeposit ? (
          <div className="mt-4 flex items-center font-Work text-xs text-wombatRed">
            <div className="h-6 w-6">
              <Image src={warnning}></Image>
            </div>
            <div>You currently have no deposit to withdraw</div>
          </div>
        ) : (
          <div className="mt-3 font-Work text-xs text-[#7A7873]">
            <span className="block text-center">
              Output is estimated.
              <br />
              If the price changes by more than{' '}
              {roundPercentage(Number(userPreference.slippage) * 100) + '% '},<br />
              your transaction will be reverted
            </span>
          </div>
        )}
      </div>
      {buttonGroup()}
      {isDisplayModal && renderModal()}
    </>
  )
}
