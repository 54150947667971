import React from 'react'
import { ASSETS } from '../../../constants/contract/asset'
import { POOLS } from '../../../constants/contract/pool'
import { useWeb3 } from '../../../context/Web3Context'

import DropdownInput from '../../inputs/DropdownInput'
import TokenImage from '../../TokenImage'
import { useUserVoteAllocationTable } from '../UserVoteAllocationTable/UserVoteAllocationTableContext'
import { TOKENS } from '../../../constants/contract'

function GaugeSelectionDropdown() {
  return (
    <DropdownInput
      placeholderProps={{ text: 'ADD GAUGE' }}
      optionsMaxHeight="190px"
      width="140px"
      enablePortal
    >
      <DropdownOptions />
    </DropdownInput>
  )
}

export default GaugeSelectionDropdown

const DropdownOptions = () => {
  const { chainId } = useWeb3()
  const {
    votedAndAddedGaugeList,
    actions: { addNewGauge },
  } = useUserVoteAllocationTable()
  return (
    <>
      {Object.values(POOLS[chainId]).map((pool) => {
        // remove added gauges from the options
        const filteredDropdownInputOptions = pool.supportedAssetTokenSymbols.filter(
          (assetTokenSymbol) => {
            return (
              ASSETS[chainId][pool.label]?.[assetTokenSymbol]?.enableVoting &&
              !votedAndAddedGaugeList.some(
                (gauge) =>
                  gauge.poolSymbol === pool.label && gauge.assetTokenSymbol === assetTokenSymbol
              )
            )
          }
        )
        return (
          // hide pool name when there are no options left
          filteredDropdownInputOptions.length > 0 && (
            <>
              <DropdownInput.Option key={pool.label} seperator="secondary">
                {pool.name}
              </DropdownInput.Option>
              {filteredDropdownInputOptions.map((assetTokenSymbol) => {
                const token = TOKENS[chainId][assetTokenSymbol]
                return (
                  <DropdownInput.Option
                    key={pool.label + assetTokenSymbol}
                    value={{ poolSymbol: pool.label, assetTokenSymbol }}
                    onClick={(value) => {
                      if (value) {
                        addNewGauge(value)
                      }
                    }}
                  >
                    <TokenImage tokenSymbol={assetTokenSymbol} width="18" height="18" />
                    {token?.displaySymbol}
                  </DropdownInput.Option>
                )
              })}
            </>
          )
        )
      })}
    </>
  )
}
