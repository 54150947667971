import React from 'react'
import { strToWad } from '@hailstonelabs/big-number-utils'
import clsx from 'clsx'
import Image from 'next/image'
import { PoolLabels } from '../../constants/contract/pool/PoolLabels'
import { usePoolPage } from '../../context/PoolPageContext'
import { useScrollToTop } from '../../hooks/useScroll'
import arrowRightMobile from '../../public/assets/icons/arrow-right-mobile.svg'
import arrowRight from '../../public/assets/icons/arrow-right.svg'
import { commafyWithDecimals } from '../../utils/commafy'
import { calLPPrice } from '../../utils/math'
import { formatNumberUSLocale, getDpFormat } from '../../utils/numberFormat'
import FormattedNumber from '../FormattedNumber'
import { POOL_STATE, TokenInfo } from '../PoolPage/index'
import WButton, { Variant } from '../WButton'
import { useCallback } from 'react'
import { usePoolCard } from '../../context/PoolCardContext'
import { SupportedChainId } from '../../constants/web3/supportedChainId'
import { CrossChainPoolInfoCard } from './crossChainPoolInfoCard'
import { Asset } from '../../constants/contract/asset/Asset'
import PoolsOnOtherChains from './poolsOnOtherChains'
import { useWeb3 } from '../../context/Web3Context'
import { ethers } from 'ethers'
import { PoolFilterGroup } from '../../constants/contract/pool/poolFilterGroup'
import { useTokenPrices } from '../../store/Prices/hooks'
import { useCashesData } from '../../store/Asset/hooks'

interface ExpandChildrenProp {
  openExpand: boolean
  depositedOrStaked: boolean | null
  DISCONNECT: boolean
  hasStaked: boolean | null
  tokenInfo: TokenInfo
  asset: Asset
  depositedAmount: string
  stakedAmount: string
  poolLabels: PoolLabels
  chainId: SupportedChainId
}
export const ExpandChildren: React.FC<ExpandChildrenProp> = ({
  openExpand,
  depositedOrStaked,
  DISCONNECT,
  hasStaked,
  tokenInfo,
  asset,
  depositedAmount,
  stakedAmount,
  poolLabels,
  chainId,
}) => {
  const { chainId: currentChainId } = useWeb3()
  const { scrollToTop } = useScrollToTop()
  const { setPoolState, selectedPoolFilterGroup } = usePoolPage()
  const { handlePoolCardInput } = usePoolCard()
  const { lpTokenToTokenRates } = useCashesData()
  const tokenPrices = useTokenPrices()
  const tokenPriceWad = strToWad(tokenPrices[tokenInfo.symbol])
  const myDepositAmount = Number(depositedAmount) + Number(stakedAmount)
  const getTokenAmountInUsd = useCallback(
    (tokenAmount: string) => {
      return calLPPrice(
        strToWad(tokenAmount),
        lpTokenToTokenRates[poolLabels][tokenInfo.symbol] ?? null,
        tokenPriceWad
      )
    },
    [lpTokenToTokenRates, poolLabels, tokenInfo.symbol, tokenPriceWad]
  )

  const setupOverflowHidden = () => {
    document.body.classList.add('overflow-hidden')
    const main = document.getElementById('main')
    const poolList = document.getElementById('pool-list')

    if (main) {
      main.classList.add('overflow-hidden', 'h-screen')
      main.classList.remove('max-h-screen')
    }

    if (poolList) {
      poolList.classList.add('hidden')
    }
  }

  const myDepositAmountInUsd = getTokenAmountInUsd(myDepositAmount.toString()) ?? '0'
  const stakedAmountInUsd = getTokenAmountInUsd(stakedAmount) ?? '0'

  return (
    <div
      className={clsx(
        openExpand ? 'border-wombatPurple2 transition-all' : 'max-h-0 overflow-hidden'
      )}
    >
      {tokenInfo.poolLabel === PoolLabels.CROSS_CHAIN &&
        selectedPoolFilterGroup === PoolFilterGroup.ALL && (
          // Show info on the crosschain pools on the current chain
          <CrossChainPoolInfoCard
            depositedOrStaked={depositedOrStaked}
            DISCONNECT={DISCONNECT}
            hasStaked={hasStaked}
            tokenInfo={tokenInfo}
            asset={asset}
            chainId={chainId}
          />
        )}

      {selectedPoolFilterGroup !== PoolFilterGroup.CROSS_CHAIN && (
        <div
          className={clsx(
            'relative flex justify-around px-3 py-4 md:justify-between lg:px-8',
            'select-none  border-t-0 border-wombatPurple2 '
          )}
          style={{ background: '#E4D5EA' }}
        >
          <div className="hidden md:contents">
            <Image alt={''} src={arrowRight} style={{ transform: 'translate(2%)' }} layout="fill" />
          </div>

          <div className="contents md:hidden">
            <Image
              alt={''}
              src={arrowRightMobile}
              style={{ transform: 'translate(2%)' }}
              layout="fill"
            />
          </div>

          {/* liquidity */}
          <div className="flex flex-col-reverse md:flex-row ">
            <WButton
              variant={Variant.GRADIENT}
              className={`pool-${tokenInfo.symbol}-liquidity my-auto`}
              onClick={() => {
                setPoolState(POOL_STATE.DEPOSIT)
                scrollToTop()
                setupOverflowHidden()
                handlePoolCardInput(poolLabels, tokenInfo)
              }}
              width="lg:w-40 md:w-32 w-32"
            >
              LIQUIDITY
            </WButton>
            <div className="ml:0 flex flex-col text-xs text-wombatBrown lg:ml-8 ">
              <div className="flex flex-col items-start md:flex-row ">
                <div className="mr-2 mb-1 lg:mb-0">My Deposit:</div>
                <div className="flex-col">
                  <div className="group relative mb-1 text-left lg:mb-0">
                    <span className="text-xs font-semibold">{`${formatNumberUSLocale(
                      myDepositAmount.toFixed(2)
                    )}`}</span>
                    <span className="text-xs font-semibold">{` LP-${asset.displaySymbol}`}</span>
                    <div className="tooltip-brown left flex py-2 px-3 text-center text-sm">
                      <p className="text-white">{commafyWithDecimals(myDepositAmount)}&nbsp;</p>
                      <p className="w-fit text-white">{` LP-${asset.displaySymbol}`}</p>
                    </div>
                  </div>
                  <div className="group relative mb-1 min-w-[150px] text-left text-wombatPurple1 lg:mb-0">
                    ($
                    {myDepositAmount < 0.01 && myDepositAmount != 0
                      ? '< 0.01'
                      : formatNumberUSLocale(
                          Number(ethers.utils.formatEther(myDepositAmountInUsd)).toFixed(2)
                        )}
                    )
                    <div className="tooltip-brown left-1/2 py-2 px-3 text-sm">
                      <p className="text-white">
                        ${getDpFormat(Number(ethers.utils.formatEther(myDepositAmountInUsd)), 4)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-start">
                <div className="mr-2 mb-2 lg:mb-0">Pool Share:</div>
                <FormattedNumber
                  amount={
                    tokenInfo.liability
                      ? (Number(depositedAmount) + Number(stakedAmount)) / tokenInfo.liability
                      : 0
                  }
                  threshold={0.0001}
                  showColor={false}
                  noMargin
                />
              </div>
            </div>
          </div>
          {/* staking */}
          <div className="ml-3 flex flex-col-reverse md:flex-row lg:min-w-[340px]">
            <WButton
              variant={!depositedOrStaked ? Variant.LIGHT_PURPLE : Variant.GRADIENT}
              disabled={!depositedOrStaked}
              onClick={() => {
                setPoolState(POOL_STATE.STAKE)
                scrollToTop()
                setupOverflowHidden()
                handlePoolCardInput(poolLabels, tokenInfo)
              }}
              className={`pool-${tokenInfo.symbol}-staking my-auto`}
              width="lg:w-40 md:w-32 w-32"
            >
              STAKING
            </WButton>
            <div className="ml:0 flex flex-col  text-xs text-wombatBrown lg:ml-6 ">
              <div className="flex flex-col items-start  md:flex-row">
                <div className="mr-2 mb-1 lg:mb-0">Staked Deposit:</div>
                <div className="flex-col">
                  <div className="group relative mb-1 justify-center lg:mb-0">
                    <span className="text-xs font-semibold">{`${formatNumberUSLocale(
                      Number(stakedAmount).toFixed(2)
                    )}`}</span>
                    <span className="text-xs font-semibold">{` LP-${asset.displaySymbol}`}</span>
                    <div className="tooltip-brown left-1/2 min-w-[150px] py-2 px-3 text-center text-sm">
                      <p className="text-white">
                        {commafyWithDecimals(Number(stakedAmount)) + ` LP-${asset.displaySymbol}`}
                      </p>
                    </div>
                  </div>
                  <div className="group relative mb-1 text-left text-wombatPurple1 lg:mb-0">
                    ($
                    {Number(stakedAmount) < 0.01 && Number(stakedAmount) != 0
                      ? '< 0.01'
                      : formatNumberUSLocale(
                          Number(ethers.utils.formatEther(stakedAmountInUsd)).toFixed(2)
                        )}
                    )
                    <div className="tooltip-brown left-1/2 py-2 px-3 text-sm">
                      <p className="text-white">
                        ${getDpFormat(Number(ethers.utils.formatEther(stakedAmountInUsd)), 4)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-1 flex justify-start lg:mb-0 ">
                <div className="mr-2">Stakable:</div>
                <div className="group relative text-xs">
                  {formatNumberUSLocale(depositedAmount)}
                  <div className="tooltip-brown left-1/2 min-w-[150px]  py-2 px-3 text-center text-sm">
                    <p className="text-white">
                      {commafyWithDecimals(Number(depositedAmount)) + ` LP-${asset.displaySymbol}`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {tokenInfo.poolLabel === PoolLabels.CROSS_CHAIN &&
        selectedPoolFilterGroup === PoolFilterGroup.CROSS_CHAIN && (
          // Show info on the crosschain pools on the other chains
          <>
            {tokenInfo.supportedChainIds.map((chainId_) => (
              <React.Fragment key={chainId_}>
                {+chainId_ !== currentChainId && (
                  <PoolsOnOtherChains
                    chainId={chainId_}
                    tokenSymbol={tokenInfo.symbol}
                    asset={asset}
                    depositedOrStaked={depositedOrStaked}
                    DISCONNECT={DISCONNECT}
                    hasStaked={hasStaked}
                  />
                )}
              </React.Fragment>
            ))}
          </>
        )}
    </div>
  )
}
