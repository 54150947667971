import {
  getCommifiedFormat,
  getDpFormat,
  getMillifiedFormat,
  getSfFormat,
  strToWad,
} from '@hailstonelabs/big-number-utils'
import { utils } from 'ethers'
import React, { useMemo } from 'react'
import GaugeSelectionDropdown from '../../components/GaugeVotingPage/GaugeSelectionDropdown'
import { useUserVoteAllocationTable } from '../../components/GaugeVotingPage/UserVoteAllocationTable/UserVoteAllocationTableContext'
import EditableLabelInput from '../../components/inputs/EditableLabelInput'
import { RowProps } from '../../components/Table'
import TokenImage from '../../components/TokenImage'
import AppTooltip from '../../components/Tooltip/AppTooltip'
import { ASSETS } from '../../constants/contract/asset'
import { TokenSymbol } from '../../constants/contract/token/TokenSymbols'
import { POOLS } from '../../constants/contract/pool'
import { useWeb3 } from '../../context/Web3Context'
import { useVotingData } from '../../context/VotingDataContext'
import { TokenSymbolStringType } from '../../interfaces/common'
import useTable, { Data } from '../table/useTable'
import useBreakpoints from '../useBreakpoints'
import { TOKENS } from '../../constants/contract'
import { checkIfGaugeTableDataShown, getBribeAprWithBalanceBreakdown } from '../../utils/voting'
import { EMISSION_PAUSED_REMINDER } from '../../components/Admin/constants'
import { POOL_REWARDER_DRAINED_REMINDER } from '../../constants/infoData'
import { useVewomData } from '../../store/MulticallData/hooks'

type TableHeadData = { style?: React.CSSProperties; cell: React.ReactNode }

type RowPropsWithoutChildren = Omit<RowProps, 'children'>
type ReturnType = {
  tableData: (Data & { rowProps?: RowPropsWithoutChildren })[]
  tableHeadData: TableHeadData[]
}
function useUserVoteAllocationTableData(): ReturnType {
  const { chainId } = useWeb3()
  const vewomData = useVewomData()
  const veWomBalanceWad = useMemo(
    () => vewomData.withAccount?.vewomBalanceWad,
    [vewomData.withAccount?.vewomBalanceWad]
  )
  const { isMd } = useBreakpoints()
  const veWomBalance = utils.formatEther(veWomBalanceWad || '0')
  const {
    votedAndAddedGaugeList,
    voteWeightPercentageInputs,
    gaugesInEditMode,
    actions: {
      updateVoteWeightPercentageInput,
      enterEditMode,
      updateAvailableVoteWeightPercentageForFocusedInput,
      getMaxVoteWeightPercentageForFocusedInput,
    },
  } = useUserVoteAllocationTable()
  const {
    user,
    bribeAprData: {
      user: { actual: userActualAprs },
    },
    total: { bribeTokenBalanceInfoOfEachAsset, whiteListOfEachAsset },
  } = useVotingData()
  /**
   * Table Data
   */
  const tableHeadData: TableHeadData[] = [
    {
      style: {
        justifyContent: 'flex-start',
      },
      cell: 'Token',
    },
    {
      cell: 'Pool',
    },
    {
      style: {
        whiteSpace: 'nowrap',
      },
      cell: 'Claimable Rewards',
    },
    {
      style: {
        whiteSpace: 'nowrap',
      },
      cell: 'My APR',
    },
    {
      style: {
        whiteSpace: 'nowrap',
      },
      cell: 'My Votes',
    },
    {
      style: {
        whiteSpace: 'nowrap',
        width: '150px',
      },
      cell: 'My Votes %',
    },
  ]
  const defaultRowForAddingNewGauge = useMemo<Data & { rowProps?: RowPropsWithoutChildren }>(() => {
    return {
      id: 'default',
      rowProps: { disableHoverEffect: true },
      configs: [
        {
          cell: <GaugeSelectionDropdown />,
        },
        {
          cell: '-',
        },
        {
          cell: '-',
        },
        {
          cell: '-',
        },
        {
          cell: '-',
        },
        {
          cell: '-',
        },
      ],
    }
  }, [])

  const tableData = useMemo<ReturnType['tableData']>(() => {
    const userVoteAllocationData: ReturnType['tableData'] = []
    for (const { poolSymbol, assetTokenSymbol, id } of votedAndAddedGaugeList) {
      const pool = POOLS[chainId][poolSymbol]
      const asset = ASSETS[chainId][poolSymbol][assetTokenSymbol]
      const token = TOKENS[chainId][assetTokenSymbol]
      let myVotes = user.voteWeightOfEachAsset.vewom[poolSymbol]?.[assetTokenSymbol]

      const isShown = checkIfGaugeTableDataShown(
        strToWad(myVotes).gt('0'),
        asset?.delisted,
        asset?.enableVoting
      )
      if (!isShown) continue

      const myVotesPercentage =
        user.voteWeightOfEachAsset.percentage[poolSymbol]?.[assetTokenSymbol] || ''
      const claimableRewardsBreakdown = user.earnedBribeOfEachAsset[poolSymbol]?.[assetTokenSymbol]
      const claimableRewardsBreakdownObject =
        claimableRewardsBreakdown?.reduce((prevObj, { tokenSymbol, value }) => {
          return {
            ...prevObj,
            [tokenSymbol]: value,
          }
        }, {} as Partial<TokenSymbolStringType>) || {}
      const myVotesPercentageInput =
        voteWeightPercentageInputs[poolSymbol]?.[assetTokenSymbol] || ''

      const myAprBreakdown = userActualAprs[poolSymbol]?.[assetTokenSymbol]?.breakdown
      const isEditing = gaugesInEditMode[poolSymbol]?.[assetTokenSymbol]
      const bribeBalanceInfo = bribeTokenBalanceInfoOfEachAsset[poolSymbol]?.[assetTokenSymbol]

      const myBribeAprWithBalanceBreakdown = getBribeAprWithBalanceBreakdown(
        bribeBalanceInfo,
        myAprBreakdown,
        'none'
      )

      const filteredMyBribeAprWithBalanceBreakdown = getBribeAprWithBalanceBreakdown(
        bribeBalanceInfo,
        myAprBreakdown,
        'all'
      )

      const hasWhiteListed = whiteListOfEachAsset[poolSymbol]?.[assetTokenSymbol]

      if (isEditing) {
        // myVotes will rely on myVotesPercentageInput when it is in edit mode
        myVotes = utils.formatEther(
          strToWad(veWomBalance).mul(strToWad(myVotesPercentageInput)).div(strToWad('100'))
        )
      }

      /**
       * add data
       */
      userVoteAllocationData.push({
        id: id || `${poolSymbol}-${assetTokenSymbol}`,
        configs: [
          {
            cell: (
              <div className="table_token-wrapper--flex">
                <TokenImage tokenSymbol={assetTokenSymbol} width="24" height="24" />
                <span>
                  {token?.displaySymbol}{' '}
                  {!hasWhiteListed && (
                    <AppTooltip
                      message={EMISSION_PAUSED_REMINDER}
                      className="ml-0"
                      iconVariant="alert"
                    ></AppTooltip>
                  )}
                </span>
              </div>
            ),
          },
          {
            cell: (
              <>
                {pool?.name.replace(' Pool', '')} {asset?.delisted && '(Deprecated)'}
              </>
            ),
          },
          {
            cell:
              Object.keys(claimableRewardsBreakdownObject).length > 0 ? (
                <div className="flex flex-col gap-1">
                  {/* if a user has an APR, it means he/she will have claimable rewards */}
                  {Object.entries(claimableRewardsBreakdownObject).map(
                    ([bribeTokenSymbol, claimableReward]) => {
                      const bribeDisplaySymbol =
                        TOKENS[chainId][bribeTokenSymbol as TokenSymbol]?.displaySymbol

                      const hasClaimableReward = strToWad(claimableReward).gt(0)

                      const bribeInfo = myBribeAprWithBalanceBreakdown.find(
                        ({ bribeTokenSymbol: breakdownTokenSymbol }) =>
                          bribeTokenSymbol === breakdownTokenSymbol
                      )

                      const hasBribeBalanceDrained = strToWad(bribeInfo?.bribeBalance).isZero()
                      const hasAprGreaterThanZero = strToWad(bribeInfo?.bribeApr).gt(0)
                      return (
                        <div className="table__token-wrapper" key={bribeTokenSymbol}>
                          <TokenImage
                            tokenSymbol={bribeTokenSymbol as TokenSymbol}
                            width="24"
                            height="24"
                          />
                          <div className="flex flex-row items-center">
                            {claimableReward ? (
                              <AppTooltip
                                message={`${getSfFormat(claimableReward, 6)} ${bribeDisplaySymbol}`}
                                fullWidth
                                padding="sm"
                                place="left"
                              >
                                {getCommifiedFormat(claimableReward, 2)} {bribeDisplaySymbol}
                              </AppTooltip>
                            ) : (
                              '-'
                            )}
                            {/* has apr but the reward hasn't been activated yet */}
                            {!hasClaimableReward &&
                              hasAprGreaterThanZero &&
                              !hasBribeBalanceDrained && (
                                <AppTooltip
                                  message={`Please vote again to activate your bribe rewards.`}
                                />
                              )}
                            {hasBribeBalanceDrained && (
                              <AppTooltip
                                message={POOL_REWARDER_DRAINED_REMINDER}
                                className="ml-1"
                                iconVariant="alert"
                              ></AppTooltip>
                            )}
                          </div>
                        </div>
                      )
                    }
                  )}
                </div>
              ) : (
                '-'
              ),
          },
          {
            cell:
              filteredMyBribeAprWithBalanceBreakdown.length > 0 ? (
                <div className="flex flex-col gap-1">
                  {/* only show APR when it's APR ,claimable rewards, and bribe balance are greater than 0  */}
                  {filteredMyBribeAprWithBalanceBreakdown.map(({ bribeTokenSymbol, bribeApr }) => {
                    return (
                      <div className="table__token-wrapper" key={bribeTokenSymbol}>
                        <AppTooltip
                          key={bribeTokenSymbol}
                          message={bribeTokenSymbol}
                          fullWidth
                          padding="sm"
                          place="left"
                        >
                          <TokenImage
                            tokenSymbol={bribeTokenSymbol as TokenSymbol}
                            width="24"
                            height="24"
                          />
                        </AppTooltip>
                        <span>{getDpFormat(bribeApr ?? '0')}%</span>
                      </div>
                    )
                  })}
                </div>
              ) : (
                '-'
              ),
          },
          {
            cell: myVotes ? (
              <AppTooltip
                message={`${getDpFormat(myVotes, 2)} veWOM`}
                fullWidth
                padding="sm"
                place="left"
                centerChildren
              >
                {getMillifiedFormat(myVotes)} veWOM
              </AppTooltip>
            ) : (
              '-'
            ),
          },
          {
            cell: (
              <div className="m-auto flex h-[24px] max-w-[120px] flex-row items-center justify-center gap-3">
                <EditableLabelInput
                  isEditing={gaugesInEditMode[poolSymbol]?.[assetTokenSymbol]}
                  label={getDpFormat(myVotesPercentage, 2)}
                  inputValue={myVotesPercentageInput}
                  onInputChange={(value) => {
                    updateVoteWeightPercentageInput(poolSymbol, assetTokenSymbol, value)
                  }}
                  onClickLabel={() => {
                    enterEditMode({ assetTokenSymbol, poolSymbol }, myVotesPercentage)
                  }}
                  onClickInput={() => {
                    updateAvailableVoteWeightPercentageForFocusedInput(poolSymbol, assetTokenSymbol)
                  }}
                  onClickMax={() =>
                    getMaxVoteWeightPercentageForFocusedInput(poolSymbol, assetTokenSymbol)
                  }
                />
                <span>%</span>
              </div>
            ),
          },
        ],
      })
    }

    // only show this default row on desktop
    if (!isMd) {
      userVoteAllocationData.push(defaultRowForAddingNewGauge)
    }
    return userVoteAllocationData
  }, [
    bribeTokenBalanceInfoOfEachAsset,
    chainId,
    defaultRowForAddingNewGauge,
    enterEditMode,
    gaugesInEditMode,
    getMaxVoteWeightPercentageForFocusedInput,
    isMd,
    updateAvailableVoteWeightPercentageForFocusedInput,
    updateVoteWeightPercentageInput,
    user.earnedBribeOfEachAsset,
    user.voteWeightOfEachAsset.percentage,
    user.voteWeightOfEachAsset.vewom,
    userActualAprs,
    veWomBalance,
    voteWeightPercentageInputs,
    votedAndAddedGaugeList,
    whiteListOfEachAsset,
  ])

  const processedTable = useTable({
    tableData,
  })
  return {
    tableData: processedTable.tableData,
    tableHeadData,
  }
}

export default useUserVoteAllocationTableData
