import { PoolConfig } from '..'
import { MasterWombatId } from '../../../masterWombat'
import { PoolLabels } from '../../PoolLabels'
import { PoolFilterGroup } from '../../poolFilterGroup'

/** Pool page follows this order  */
export const BSC_MAINNET_POOL_CONFIG: PoolConfig = {
  [PoolLabels.CROSS_CHAIN]: {
    name: 'Cross-Chain Pool',
    filterGroup: PoolFilterGroup.CROSS_CHAIN,
    address: '0x1ee15673e07105Bcf360139fa8CafeBDd7754BeF',
  },
  [PoolLabels.MAIN]: {
    name: 'Main Pool',
    filterGroup: PoolFilterGroup.MAIN,
    address: '0x312bc7eaaf93f1c60dc5afc115fccde161055fb0',
  },
  [PoolLabels.SIDE]: {
    name: 'LisUSD Pool',
    filterGroup: PoolFilterGroup.STABLES,
    address: '0x0520451b19ad0bb00ed35ef391086a692cfc74b2',
  },
  [PoolLabels.USDV]: {
    filterGroup: PoolFilterGroup.STABLES,
    address: '0xC897a2Ae2E45f0D7ba8cbE397208C3e9f8914A9f',
  },
  [PoolLabels.lUSD]: {
    filterGroup: PoolFilterGroup.STABLES,
    address: '0x84a14A10E7258C68413168c98E905483f9183D7a',
  },
  [PoolLabels.wBETH]: {
    filterGroup: PoolFilterGroup.LSD,
    address: '0x8b892b6Ea1d0e5B29b719d6Bd6eb9354f1cDE060',
  },
  [PoolLabels.BNBx]: {
    filterGroup: PoolFilterGroup.LSD,
    address: '0x8df1126de13bcfef999556899f469d64021adbae',
  },
  [PoolLabels.slisBNB]: {
    filterGroup: PoolFilterGroup.LSD,
    address: '0xF1e604e9A31c3b575f91CF008445B7ce06BF3fef',
  },
  [PoolLabels.frxETH]: {
    filterGroup: PoolFilterGroup.LSD,
    address: '0x2ea772346486972e7690219c190dadda40ac5da4',
  },
  [PoolLabels.FACTORY_STABLES]: {
    name: 'FRAX Pool',
    filterGroup: PoolFilterGroup.STABLES,
    address: '0x48f6a8a0158031baf8ce3e45344518f1e69f2a14',
  },
  [PoolLabels.rBNB]: {
    filterGroup: PoolFilterGroup.LSD,
    address: '0x0592083B285aa75B9c8BaD2485C6cCCF93cCC348',
  },
  [PoolLabels.zBNB]: {
    filterGroup: PoolFilterGroup.LSD,
    address: '0x9a39f4AB3f52026432835dEe6D3DB721D95f3D28',
  },
  [PoolLabels.stkBNB]: {
    filterGroup: PoolFilterGroup.LSD,
    address: '0xb0219a90ef6a24a237bc038f7b7a6eac5e01edb0',
  },
  [PoolLabels.ankrBNB]: {
    filterGroup: PoolFilterGroup.LSD,
    address: '0x6f1c689235580341562cdc3304e923cc8fad5bfa',
  },
  [PoolLabels.BNB]: {
    masterWombatId: MasterWombatId.MW2,
    isHiddenInPoolPage: true,
    filterGroup: PoolFilterGroup.UNAVALIABLE,
    address: '0x0029b7e8e9ed8001c868aa09c74a1ac6269d4183',
  },
  [PoolLabels.BNBy]: {
    filterGroup: PoolFilterGroup.LSD,
    address: '0xbed9b758a681d73a95ab4c01309c63aa16297b80',
  },
  [PoolLabels.ankrETH]: {
    filterGroup: PoolFilterGroup.LSD,
    address: '0x1b507b97c89eDE3E40d1b2Ed92972197c6276D35',
  },
  // For standalone pool config
  [PoolLabels.SMART_HAY]: {
    isHiddenInPoolPage: true,
    filterGroup: PoolFilterGroup.STABLES,
    address: '0xa61dccC6c6E34C8Fbf14527386cA35589e9b8C27',
    name: 'Smart HAY Pool',
  },
  [PoolLabels.Overnight]: {
    filterGroup: PoolFilterGroup.STABLES,
    address: '0x9498563e47d7cfdfa22b818bb8112781036c201c',
    name: 'Stable Guild Pool',
  },
  [PoolLabels.MIM]: {
    filterGroup: PoolFilterGroup.STABLES,
    address: '0xb8b1b72a9b9ba90e2539348fec1ad6b265f9f684',
  },
  [PoolLabels.BOB]: {
    filterGroup: PoolFilterGroup.STABLES,
    address: '0xea6cdd9e8819bbf7f8791e7d084d9f0a6afa7892',
  },
  [PoolLabels.axlUSDC]: {
    filterGroup: PoolFilterGroup.STABLES,
    address: '0x8ad47d7ab304272322513ee63665906b64a49da2',
  },
  [PoolLabels.iUSD]: {
    filterGroup: PoolFilterGroup.STABLES,
    address: '0x277e777f7687239b092c8845d4d2cd083a33c903',
  },
  [PoolLabels.USDD]: {
    filterGroup: PoolFilterGroup.STABLES,
    address: '0x05f727876d7c123b9bb41507251e2afd81ead09a',
  },
  [PoolLabels.USDS]: {
    filterGroup: PoolFilterGroup.STABLES,
    address: '0x0c735f84BD7EDA8F8176236091AF8068Bb6C41dE',
  },
  [PoolLabels.zUSD]: {
    filterGroup: PoolFilterGroup.STABLES,
    address: '0xC26b7Cbe7e695a0d11a8cB96140D1Cd502945A2C',
  },
  [PoolLabels.wmxWOM]: {
    filterGroup: PoolFilterGroup.ECO,
    address: '0xeeb5a751e0f5231fc21c7415c4a4c6764f67ce2e',
  },
  [PoolLabels.mWOM]: {
    filterGroup: PoolFilterGroup.ECO,
    address: '0x083640c5dbd5a8ddc30100fb09b45901e12f9f55',
  },
  [PoolLabels.qWOM]: {
    filterGroup: PoolFilterGroup.ECO,
    address: '0x2c5464b9052319e3d76f8279031f04e4b7fd7955',
  },
}
