var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"d81d20eaa82387183b598305934fc06b5a86cc67"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://db3a41048c75437eb38319b3540f0b94@o1217068.ingest.sentry.io/6359152',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.1,
  integrations: [new CaptureConsoleIntegration({ levels: ['error'] })],
  beforeSend: (event) => {
    // Don't log errors from localhost.
    if (event.request.url.includes('http://localhost')) {
      return null;
    }
    return event;
  }
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
