import { useEffect, useMemo, useRef, useState } from 'react'
import { useUserPreference } from '../../context/userPreferenceContext'
import useDidClickOutside from '../../hooks/useDidClickOutside'
import { roundPercentage } from '../../utils/numberFormat'
import Modal from '../Modal'
import SettingButton from '../SettingButton'
import SettingInput from '../SettingInput'
import Tooltip from '../Tooltip'
import CrossChainSwapSettingBox from './CrossChainSwapSettingBox'
import ToggleSlider from '../ToggleSlider'
import IconAlert from '../Icons/IconAlert'

type Props = {
  isOpen: boolean
  onClose: () => void
  hasWithdraw?: boolean
  isCrossChainSettingShown?: boolean
}

export default function SettingModal({
  isOpen,
  onClose,
  hasWithdraw = false,
  isCrossChainSettingShown = false,
}: Props) {
  const wrapperRef = useRef(null)
  useDidClickOutside([wrapperRef], () => {
    onClose()
  })
  const {
    userPreference,
    actions: {
      updateSlippage,
      updateTransactionDeadline,
      updateWithdrawal,
      updateIsRecipientShown,
    },
  } = useUserPreference()

  const [slippage, setSlippage] = useState<string>()
  const [slippageForDisplay, setSlippageForDisplay] = useState<string>('')
  const [transactionDeadline, setTransactionDeadline] = useState<string>()
  const [transactionDeadlineForDisplay, setTransactionDeadlineForDisplay] = useState<string>('')
  const [withd, setWithd] = useState<boolean>()

  const [enableCustomSwapSlippage, setEnableCustomSwapSlippage] = useState<boolean>()
  const [enableCustomTransactionDeadline, setEnableCustomTransactionDeadline] = useState<boolean>()
  const [isRecipientShown, setIsRecipientShown] = useState(userPreference.isRecipientShown || false)

  const maxSlippageWarningText = useMemo(() => {
    const amountNum = Number(slippageForDisplay)
    if (amountNum > 5) {
      return 'The maximum slippage is 5%'
    }
    return ''
  }, [slippageForDisplay])

  useEffect(() => {
    // init
    if (
      enableCustomSwapSlippage === undefined &&
      ![undefined, '0.001', '0.005', '0.01', '0.02'].includes(userPreference.slippage)
    ) {
      setEnableCustomSwapSlippage(true)
    }
    if (
      enableCustomTransactionDeadline === undefined &&
      ![undefined, '60', '300'].includes(userPreference.transactionDeadline)
    ) {
      setEnableCustomTransactionDeadline(true)
    }

    setSlippage(userPreference.slippage)
    setTransactionDeadline(userPreference.transactionDeadline)
    setWithd(userPreference.withdrawal)
  }, [enableCustomSwapSlippage, enableCustomTransactionDeadline, userPreference])

  useEffect(() => {
    setSlippageForDisplay(roundPercentage(Number(userPreference.slippage) * 100).toString())
    setTransactionDeadlineForDisplay((Number(userPreference.transactionDeadline) / 60).toString())
  }, [userPreference])

  return (
    <Modal title="Settings" isOpen={isOpen} onClose={onClose} width={418} height={418}>
      <div
        style={{
          marginTop: '10px',
        }}
        className="flex flex-col space-y-5"
      >
        {/* Cross Chain Swap */}
        {isCrossChainSettingShown && <CrossChainSwapSettingBox />}
        {/* Swap */}
        <div className="">
          <div className="mb-2 flex">
            <div className="mr-2 text-[14px] font-normal text-wombatPurple1">
              Slippage Tolerance
            </div>

            <Tooltip width={330}>
              The maximum amount of slippage you are willing to accept in an exchange. Your
              transaction will not go through if the price unfavorably fluctuates beyond your
              slippage tolerance.
            </Tooltip>
          </div>
          <form>
            <div className="grid grid-cols-4 gap-4 text-xs text-wombatBrown1">
              {/* {0.1%} */}
              <SettingButton
                onClick={() => {
                  setSlippageForDisplay('')
                  updateSlippage('0.001')
                  setEnableCustomSwapSlippage(false)
                }}
                checked={slippage === '0.001' && !enableCustomSwapSlippage}
              >
                0.1%
              </SettingButton>
              {/* 0.5% */}
              <SettingButton
                onClick={() => {
                  setSlippageForDisplay('')
                  updateSlippage('0.005')
                  setEnableCustomSwapSlippage(false)
                }}
                checked={slippage == '0.005' && !enableCustomSwapSlippage}
              >
                0.5%
              </SettingButton>
              {/* 1% */}
              <SettingButton
                onClick={() => {
                  setSlippageForDisplay('')
                  updateSlippage('0.01')
                  setEnableCustomSwapSlippage(false)
                }}
                checked={slippage == '0.01' && !enableCustomSwapSlippage}
              >
                1%
              </SettingButton>
              {/* 2% */}
              <SettingButton
                onClick={() => {
                  setSlippageForDisplay('')
                  updateSlippage('0.02')
                  setEnableCustomSwapSlippage(false)
                }}
                checked={slippage == '0.02' && !enableCustomSwapSlippage}
              >
                2%
              </SettingButton>
            </div>
            <div className="text-xs text-wombatBrown1">
              {/* Custom Swap Slippage */}
              <SettingInput
                onClick={() => {
                  if (!enableCustomSwapSlippage) {
                    setSlippageForDisplay('1')
                    updateSlippage('0.02')
                  }
                  setEnableCustomSwapSlippage(true)
                }}
                placeHolder="1"
                checked={!!enableCustomSwapSlippage}
                inputForDisplay={enableCustomSwapSlippage ? slippageForDisplay : ''}
                warningMsg={maxSlippageWarningText}
                onAmountChanged={(amount) => {
                  setSlippageForDisplay(amount)
                  const amountNum = Number(amount)
                  if (amount.slice(-1) !== '.' && amountNum > 0 && amountNum <= 5) {
                    updateSlippage((amountNum / 100).toString())
                  }
                }}
              >
                &nbsp;{'%'}
              </SettingInput>
            </div>
          </form>
          {Number(slippageForDisplay) > 2 && Number(slippageForDisplay) <= 5 && (
            <div className={'flex items-center gap-2 text-xs text-yellow-500'}>
              <IconAlert></IconAlert>
              Your transaction may be frontrun and result in an unfavorable trade
            </div>
          )}
        </div>
        {/* withdrawal */}
        {hasWithdraw && (
          <div className="">
            <div className="flex ">
              <div className="mb-2 mr-2 text-[14px] font-normal text-wombatPurple1">
                Withdrawal in other assets
              </div>
              <div className="relative top-1">
                <Tooltip>Enables withdrawal of tokens other than your original deposit.</Tooltip>
              </div>
            </div>

            <form>
              <div className="grid grid-cols-3 gap-4 text-xs text-wombatBrown1 md:grid-cols-4">
                {/* On */}
                <SettingButton
                  onClick={() => {
                    updateWithdrawal(true)
                  }}
                  checked={withd === true}
                >
                  On
                </SettingButton>
                {/* Off */}
                <SettingButton
                  onClick={() => {
                    updateWithdrawal(false)
                  }}
                  checked={withd === false}
                >
                  Off
                </SettingButton>
              </div>
            </form>
          </div>
        )}

        {/* Transaction */}
        <div>
          <div className="mb-2 flex">
            <div className="mr-2 text-[14px] font-normal text-wombatPurple1">
              Transaction Deadline
            </div>

            <Tooltip>
              The maximum time that you allow your swap to remain pending. Your transaction will
              fail if it is still pending beyond this time.
            </Tooltip>
          </div>
          <form>
            <div className="grid grid-cols-3 gap-4 text-xs text-wombatBrown1 md:grid-cols-4">
              {/* {1 minute} */}
              <SettingButton
                onClick={() => {
                  setTransactionDeadlineForDisplay('')
                  updateTransactionDeadline('60')
                  setEnableCustomTransactionDeadline(false)
                }}
                checked={transactionDeadline == '60' && !enableCustomTransactionDeadline}
              >
                1 minute
              </SettingButton>
              {/* {5 minute} */}
              <SettingButton
                onClick={() => {
                  setTransactionDeadlineForDisplay('')
                  updateTransactionDeadline('300')
                  setEnableCustomTransactionDeadline(false)
                }}
                checked={transactionDeadline == '300' && !enableCustomTransactionDeadline}
              >
                5 minutes
              </SettingButton>
              {/* {30 minute} */}
              <SettingButton
                onClick={() => {
                  setTransactionDeadlineForDisplay('')
                  updateTransactionDeadline('1800')
                  setEnableCustomTransactionDeadline(false)
                }}
                checked={transactionDeadline == '1800' && !enableCustomTransactionDeadline}
              >
                30 minutes
              </SettingButton>
              {/* Custom Transaction Deadline */}
            </div>
            <div className="text-xs text-wombatBrown1">
              <SettingInput
                onClick={() => {
                  if (!enableCustomTransactionDeadline) {
                    setTransactionDeadlineForDisplay('60')
                    updateTransactionDeadline('3600')
                  }
                  setEnableCustomTransactionDeadline(true)
                }}
                placeHolder="60"
                checked={!!enableCustomTransactionDeadline}
                inputForDisplay={
                  enableCustomTransactionDeadline ? transactionDeadlineForDisplay : ''
                }
                onAmountChanged={(amount) => {
                  setTransactionDeadlineForDisplay(amount)
                  if (amount.slice(-1) !== '.') {
                    updateTransactionDeadline((Number(amount) * 60).toString())
                  }
                }}
              >
                &nbsp;{'minutes'}
              </SettingInput>
            </div>
          </form>
        </div>

        {/* Show Recipient Address */}
        <div className="flex flex-row items-center justify-between">
          <p className="text-[14px] font-normal text-wombatPurple1">Show recipient address</p>
          <ToggleSlider
            title=""
            onToggleChange={() => {
              setIsRecipientShown((prev) => {
                const newValue = !prev
                updateIsRecipientShown(newValue)
                return newValue
              })
            }}
            isChecked={isRecipientShown}
            containerClassName="border-none"
            className="bg-wombatBrown1"
          />
        </div>
      </div>
    </Modal>
  )
}
