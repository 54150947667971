import React from 'react'

interface TickIcon {
  disabled?: boolean
  handleIconOnClick: React.MouseEventHandler<HTMLDivElement>
}

const TickIcon = ({ disabled, handleIconOnClick }: TickIcon) => {
  return (
    <div
      onClick={handleIconOnClick}
      className={`${disabled ? 'cursor-default' : 'cursor-pointer'}`}
    >
      <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.410767 4.58918L1.58928 3.41067L4.29985 6.12125L10.3103 0.11084L11.4888 1.28935L4.29985 8.47827L0.410767 4.58918Z"
          fill={disabled ? '#DBD1C6' : '#B672D6'}
        />
      </svg>
    </div>
  )
}

export default TickIcon
