import { getMillifiedFormat } from '@hailstonelabs/big-number-utils'
import { BigNumber, BigNumberish, ContractTransaction, utils } from 'ethers'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TOKENS } from '../../constants/contract'
import { Asset } from '../../constants/contract/asset/Asset'
import { DELAY_TIME_ANIMATION_GIF } from '../../constants/common'
import { ROUTERS } from '../../constants/contract/router'
import { ErrorMessages, PROVIDER_ERROR_CODES } from '../../context/errorMessage'
import { useMasterWombat } from '../../context/masterWombatContext'
import { useWeb3 } from '../../context/Web3Context'
import { useTxnReceipt } from '../../context/TxnReceiptProvider'
import { useUserPreference } from '../../context/userPreferenceContext'
import useApproval, { TokenApprovalState } from '../../hooks/useApproval'
import { useDebounce } from '../../hooks/useDebounce'
import { assertUnreachable, calculateGasMargin } from '../../utils'
import { formatNumberUSLocale, roundPercentage } from '../../utils/numberFormat'
import { getMinimumReceived, getPotentialDepositForCrossChainPoolQuotation } from '../../utils/pool'
import Accordion from '../Accordion'
import { getDeadlineFromNow } from '../../utils/utils'
import FormattedAmount from '../FormattedAmount'
import FormattedNumber from '../FormattedNumber'
import WaitingModal from '../Modal/WaitingModal'
import PoolInput from '../PoolInput'
import { POOL_STATE } from '../PoolPage'
import Tooltip from '../Tooltip'
import TooltipNum from '../TooltipNum'
import TransactionFailedModal from '../TransactionFailedModal'
import Submitted from '../TransactionSubmittedModal'
import WButton, { Variant } from '../WButton'
import { Pool } from '../../constants/contract/pool/Pool'
import { useContract } from 'wagmi'
import { NATIVE_WRAPPED_TOKEN_IN_CHAIN } from '../../constants/contract/token'
import { PoolLabels } from '../../constants/contract/pool/PoolLabels'
import { useAppDispatch } from '../../store/hooks'
import { addErrorToast, addSuccessToast } from '../../store/Toast/actions'
import { useCashesData } from '../../store/Asset/hooks'
import { useTokenData } from '../../store/MulticallData/hooks'

interface Props {
  currentPool: Pool
  asset: Asset
  onTxnSubmited: (txn: ContractTransaction) => void
  redirect: (value: POOL_STATE | null) => void
}

enum TransactionState {
  IDLE,
  LOADING,
  SUBMITED,
  SUCCESS,
  FAILED,
  UNKNOWN,
}

// 'In bankrun situation, LPs might only be able to withdraw in the over-covered tokens.'

export default function Deposit({ currentPool, asset, onTxnSubmited, redirect }: Props) {
  const { chainId, signer, account, readonlyProvider } = useWeb3()
  const [tokenAmountBn, setTokenAmountBn] = useState<BigNumber | null>(null)
  const [depositState, setDepositState] = useState<TransactionState>(TransactionState.IDLE)
  const [txHash, setTxHash] = useState('')
  const [isDisplayGifFlyToTheMoon, setIsDisplayGifFlyToTheMoon] = useState(false)
  const {
    userPreference: { transactionDeadline, slippage },
  } = useUserPreference()
  const token = TOKENS[chainId][asset.symbol]
  const tokenAddress = token?.address
  const isFromNativeToken = asset.symbol === NATIVE_WRAPPED_TOKEN_IN_CHAIN[chainId]
  const poolContract = useContract({
    ...currentPool.get(),
    signerOrProvider: signer,
  })
  const { approvalState, tryApproval } = useApproval(
    tokenAddress || null,
    currentPool.address ?? null,
    tokenAmountBn,
    isFromNativeToken
  )
  const navigate = useNavigate()
  const routerContract = useContract({
    ...ROUTERS[chainId]?.get(),
    signerOrProvider: signer,
  })
  const { deposits, lpTokenToTokenRates, totalSupplies, maxSupply } = useCashesData()
  const { userInfos } = useMasterWombat()
  const deposit = deposits[currentPool.label][asset.symbol]
  const exchangeRate = lpTokenToTokenRates[currentPool.label][asset.symbol]
  const totalSupply = totalSupplies[currentPool.label][asset.symbol]
  const stakedAmount = userInfos[currentPool.label][asset.symbol]?.amount
  const [depositFee, setDepositFee] = useState<BigNumber>(BigNumber.from(0))
  const [depositQuote, setDepositQuote] = useState<BigNumber>(BigNumber.from(0))
  const [isStake, setIsStake] = useState(false)
  const [isDisplayGroupModal, setIsDisplayGroupModal] = useState(true)
  const { withAccount } = useTokenData()

  const balance = withAccount?.balances[asset.symbol]

  const { refreshTxnReceipt } = useTxnReceipt()

  const debounceTokenAmount = useDebounce<BigNumber | null>(tokenAmountBn)

  const [tokenAmountForDisplay, setTokenAmountForDisplay] = useState<string>('')

  const isCrossChainPool = currentPool.label === PoolLabels.CROSS_CHAIN

  /** @todo refactor quotation function */
  const updateQuote = useCallback(() => {
    const tokenAmount = debounceTokenAmount ?? BigNumber.from(0)
    if (!readonlyProvider || !chainId || tokenAmount.isZero() || !poolContract || !tokenAddress)
      return
    poolContract
      .quotePotentialDeposit(tokenAddress, tokenAmount)
      .then((quote) => {
        if (quote !== null) {
          setDepositFee(quote.reward)
          setDepositQuote(quote.liquidity)
        }
      })
      .catch((error) => {
        const errInfo =
          '@pool.quotePotentialDeposit\n' +
          `token: ${tokenAddress}\n` +
          `amount: ${tokenAmount.toString()}\n` +
          `poolAddr: ${poolContract.address}\n`
        console.error(errInfo, error)
      })
  }, [chainId, debounceTokenAmount, poolContract, readonlyProvider, tokenAddress])

  /** @todo refactor quotation function */
  const updateCrossChainPoolQuote = useCallback(async () => {
    const tokenAmount = debounceTokenAmount ?? BigNumber.from(0)
    if (!readonlyProvider || !chainId || tokenAmount.isZero() || !tokenAddress) return
    const liquidity = await getPotentialDepositForCrossChainPoolQuotation({
      chainId,
      tokenAddress,
      tokenAmountBN: tokenAmount,
    })
    if (!liquidity) return
    setDepositQuote(liquidity)
  }, [chainId, debounceTokenAmount, readonlyProvider, tokenAddress])

  useEffect(() => {
    if (isCrossChainPool) return
    updateQuote()
  }, [isCrossChainPool, updateQuote])

  useEffect(() => {
    if (!isCrossChainPool) return
    updateCrossChainPoolQuote()
  }, [isCrossChainPool, updateCrossChainPoolQuote, updateQuote])

  const dispatch = useAppDispatch()

  const handleDeposit = useCallback(
    (isStake: boolean) => async () => {
      if (!Pool || !tokenAddress || !account) {
        return
      }
      const depositAmountBn = tokenAmountBn ?? BigNumber.from('0')

      const minimumReceived = getMinimumReceived(depositQuote, slippage)
      const deadline = getDeadlineFromNow(transactionDeadline)

      try {
        setIsStake(isStake)
        setDepositState(TransactionState.LOADING)

        let txn: ContractTransaction
        if (!isFromNativeToken && poolContract) {
          const estimateGas = await poolContract.estimateGas.deposit(
            tokenAddress,
            depositAmountBn,
            minimumReceived,
            account,
            BigNumber.from(deadline),
            isStake
          )
          txn = await poolContract.deposit(
            tokenAddress,
            depositAmountBn,
            minimumReceived,
            account,
            BigNumber.from(deadline),
            isStake,
            {
              gasLimit: calculateGasMargin(estimateGas),
            }
          )
        } else {
          if (!routerContract) throw 'addLiquidityNative: router is invalid'
          txn = await routerContract.addLiquidityNative(
            currentPool.address,
            minimumReceived,
            account,
            BigNumber.from(deadline),
            isStake,
            { value: depositAmountBn }
          )
        }

        setTxHash(txn.hash)
        setIsDisplayGifFlyToTheMoon(true)
        setTokenAmountForDisplay('')
        setTokenAmountBn(null)
        setTimeout(() => {
          setDepositState(TransactionState.SUBMITED)
          setIsDisplayGifFlyToTheMoon(false) //reset default value
        }, DELAY_TIME_ANIMATION_GIF)

        // we should eventually replace this with a balance/assets/liabilities/deposit provider onTxnSubmitted(txn)
        txn.wait().then(() => {
          setDepositState(TransactionState.SUCCESS)
          dispatch(
            addSuccessToast({
              message: `${formatNumberUSLocale(tokenAmountForDisplay)} ${asset.symbol}`,
              title: isStake ? 'Deposit & Stake Completed' : 'Deposit Completed',
              txHash: txn.hash,
              childrenButton: isStake ? 'Lock WOM' : 'Stake LP',
              handleClickButton: isStake
                ? () => navigate('/boost')
                : () => redirect(POOL_STATE.STAKE),
            })
          )
          refreshTxnReceipt()
          onTxnSubmited(txn)
          setIsDisplayGroupModal(true) // reset display group modal
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (JSON.stringify(error).includes('ACTION_REJECTED')) {
          setDepositState(TransactionState.IDLE)
          const errorMessage = ErrorMessages[PROVIDER_ERROR_CODES.REQUEST_DENIED_ERROR]
          dispatch(
            addErrorToast({
              message: errorMessage.message,
              title: errorMessage.title,
            })
          )
        } else {
          dispatch(
            addErrorToast({
              message: `${formatNumberUSLocale(tokenAmountForDisplay)} ${asset.symbol}`,
              title: isStake ? 'Deposit & Stake Failed' : 'Deposit Failed',
              txHash: txHash,
            })
          )
          setDepositState(TransactionState.FAILED)
          setIsDisplayGroupModal(true) // reset display group modal
          const errInfo =
            '@pool.deposit\n' +
            `token: ${tokenAddress}\n` +
            `amount: ${depositAmountBn.toString()}\n` +
            `to: ${account}\n` +
            `shouldStake: ${isStake}\n` +
            `txHash: ${txHash}\n` +
            `poolAddr: ${currentPool.address}\n`
          console.error(errInfo, error)
        }
      }
    },
    [
      tokenAddress,
      account,
      tokenAmountBn,
      depositQuote,
      slippage,
      transactionDeadline,
      isFromNativeToken,
      poolContract,
      routerContract,
      currentPool.address,
      dispatch,
      tokenAmountForDisplay,
      asset.symbol,
      refreshTxnReceipt,
      onTxnSubmited,
      navigate,
      redirect,
      txHash,
    ]
  )

  const handleData = (): string => {
    if (tokenAmountBn && token) {
      return (
        formatNumberUSLocale(utils.formatUnits(tokenAmountBn as BigNumberish, token.decimals)) +
        ' ' +
        token.displaySymbol
      )
    }
    return '0'
  }

  useEffect(() => {
    if (!isDisplayGroupModal && depositState !== TransactionState.SUBMITED) {
      setDepositState(TransactionState.IDLE)
      setIsDisplayGroupModal(true)
    }
  }, [depositState, isDisplayGroupModal])

  const isAmountGreaterThanBalance = (): boolean => {
    if (debounceTokenAmount && balance && token) {
      return debounceTokenAmount.gt(utils.parseUnits(balance, token.decimals))
    }
    return true
  }

  const transactionModal = () => {
    const token = TOKENS[chainId][asset.symbol]
    switch (depositState) {
      case TransactionState.FAILED:
        return <TransactionFailedModal isOpen onClose={() => setIsDisplayGroupModal(false)} />
      case TransactionState.SUBMITED:
        return (
          <Submitted
            isBoostAPR={isStake}
            isAddTokenToWallet={false}
            token={token}
            hash={txHash}
            isOpen
            onClose={() => setIsDisplayGroupModal(false)}
            chainId={chainId}
          />
        )
      case TransactionState.LOADING:
        return (
          <WaitingModal
            isDisplayGifFlyToTheMoon={isDisplayGifFlyToTheMoon}
            isDisplayDepositIcon
            textAboveBalance={isStake ? 'Deposit & Stake' : 'Deposit'}
            data={handleData()}
            isOpen
            onClose={() => setIsDisplayGroupModal(false)}
          />
        )
    }
  }

  const buttonLeft = () => {
    switch (approvalState) {
      case TokenApprovalState.UNKNOWN:
      case TokenApprovalState.NETWORK_UNSUPPORTED:
        return (
          <div className="w-full">
            <WButton variant={Variant.LIGHT_PURPLE} width="w-full" height="h-10" disabled>
              APPROVE
            </WButton>
          </div>
        )
      case TokenApprovalState.NOT_APPROVED:
        return (
          <div className="w-full">
            <WButton
              variant={Variant.GRADIENT}
              width="w-full"
              height="h-10"
              className={`deposit-${asset.symbol}-approve`}
              onClick={() => {
                tryApproval()
              }}
            >
              APPROVE
            </WButton>
          </div>
        )
      case TokenApprovalState.LOADING:
        return (
          <div className="w-full">
            <WButton variant={Variant.GRADIENT} width="w-full" height="h-10" isLoading={true} />
          </div>
        )
      case TokenApprovalState.APPROVED:
        return (
          <div className="w-full md:mt-0">
            <WButton variant={Variant.LIGHT_PURPLE} width="w-full" height="h-10" disabled>
              APPROVED
            </WButton>
          </div>
        )
      default:
        assertUnreachable(approvalState)
    }
  }

  // No max supply means there is no limit for deposit
  const hasMaxSupply = maxSupply[currentPool.label][asset.symbol]?.gt(0)

  const gtOrEqMaxSupply =
    !!hasMaxSupply &&
    depositQuote.add(totalSupply ?? 0).gte(maxSupply[currentPool.label][asset.symbol] ?? 0)

  const isButtonDisabled = !tokenAmountBn?.gt(0) || isAmountGreaterThanBalance() || gtOrEqMaxSupply

  const buttonRight = () => {
    switch (true) {
      case approvalState !== TokenApprovalState.APPROVED:
        return (
          <div className="relative flex w-full flex-col">
            <WButton variant={Variant.LIGHT_PURPLE} width="w-full" height="h-10" disabled>
              <p className="hidden md:block">DEPOSIT & STAKE</p>
              <div className="block leading-none md:hidden">
                DEPOSIT &
                <br />
                STAKE
              </div>
            </WButton>

            <WButton
              variant={Variant.LIGHT_PURPLE}
              width="w-full"
              height="h-10"
              className="mt-2"
              disabled
            >
              DEPOSIT
            </WButton>
          </div>
        )

      case depositState === TransactionState.LOADING:
        return (
          <div className="relative flex w-full flex-col">
            <WButton
              variant={!isStake ? Variant.LIGHT_PURPLE : Variant.GRADIENT}
              width="w-full"
              height="h-10"
              isLoading={isStake}
              disabled={!isStake}
            >
              {!isStake && (
                <div>
                  <p className="hidden md:block">DEPOSIT & STAKE</p>
                  <div className="block leading-none md:hidden">
                    DEPOSIT &
                    <br />
                    STAKE
                  </div>
                </div>
              )}
            </WButton>

            <WButton
              isLoading={!isStake}
              disabled={isStake}
              width="w-full"
              height="h-10"
              className="mt-2"
              variant={isStake ? Variant.LIGHT_PURPLE : Variant.PURPLE}
            >
              {isStake && 'DEPOSIT'}
            </WButton>
          </div>
        )

      case approvalState === TokenApprovalState.APPROVED:
        return (
          <div className="relative flex w-full flex-col">
            <WButton
              variant={isButtonDisabled ? Variant.LIGHT_PURPLE : Variant.GRADIENT}
              width="w-full"
              height="h-10"
              onClick={handleDeposit(true)}
              className={`deposit-${asset.symbol}-deposit`}
              disabled={isButtonDisabled}
            >
              <p className="hidden md:block">DEPOSIT & STAKE</p>
              <div className="block leading-none md:hidden">
                DEPOSIT &
                <br />
                STAKE
              </div>
            </WButton>
            <WButton
              variant={isButtonDisabled ? Variant.LIGHT_PURPLE : Variant.GRADIENT}
              width="w-full"
              height="h-10"
              className="mt-2"
              onClick={handleDeposit(false)}
              disabled={isButtonDisabled}
            >
              DEPOSIT
            </WButton>
          </div>
        )

      default:
        break
    }
  }

  const buttonGroup = () => {
    return (
      <div className="flex flex-row text-lg">
        {buttonLeft()}
        <div
          className={`flex-none ${
            approvalState !== TokenApprovalState.APPROVED || !account
              ? 'mt-4 w-8 md:mt-5'
              : 'mt-5 w-4'
          } border-t-1 border-wombatPurple3`}
        ></div>
        {approvalState === TokenApprovalState.APPROVED && account && (
          <div className="mt-5 h-10 w-4 flex-none border-l-1 border-t-1 border-b-1 border-wombatPurple3"></div>
        )}

        {buttonRight()}
      </div>
    )
  }

  const newPoolShare =
    deposit &&
    stakedAmount &&
    totalSupply &&
    depositQuote &&
    !totalSupply.add(depositQuote).isZero()
      ? deposit
          .add(stakedAmount)
          .add(depositQuote)
          .mul(utils.parseEther('1'))
          .div(totalSupply.add(depositQuote))
      : 0

  return (
    <>
      {token && (
        <PoolInput
          balance={balance}
          poolState={POOL_STATE.DEPOSIT}
          token={token}
          selectedAsset={asset}
          setTokenAmount={setTokenAmountBn}
          tokenAmountForDisplay={tokenAmountForDisplay}
          setTokenAmountForDisplay={setTokenAmountForDisplay}
          handleChangeAmount={(value) => {
            setTokenAmountBn(value)
          }}
          amountTokenMapping={balance ?? '0'}
          decimals={token.decimals}
        />
      )}
      {/** Hide reward info for cross chain pool */}
      <div className="py-4 font-Work text-sm text-wombatBrown">
        {!isCrossChainPool && (
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <span className="mr-1">Reward </span>
              <Tooltip pointerEvent={true} isTextPositionRightOnMobile={true}>
                Incentives to converge the coverage ratio of the token.{' '}
                <a
                  className="font-semibold hover:underline"
                  target="_blank"
                  href="https://docs.wombat.exchange/concepts/fees/deposit-gain-and-withdrawal-fee"
                  rel="noreferrer"
                >
                  Learn More
                </a>
              </Tooltip>
            </div>
            <span>
              {depositFee.gt(BigNumber.from(0)) ? (
                <FormattedAmount
                  amount={Number(utils.formatEther(depositFee.toString()))}
                  threshold={0.0001}
                  showColor={false}
                />
              ) : (
                '0.00'
              )}
              {` ${token?.displaySymbol}`}
            </span>
          </div>
        )}
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <span className="mr-2">Estimated LP tokens </span>
            {/* TODO: confirm tooltip content */}
            <Tooltip pointerEvent={true} isTextPositionRightOnMobile={true}>
              <span>
                Output is estimated.
                <br />
                If the price changes by more than {roundPercentage(Number(slippage) * 100)}
                %,
                <br />
                your transaction will be reverted.
              </span>
            </Tooltip>
          </div>
          <span>
            {depositQuote.gt(BigNumber.from(0)) ? (
              <TooltipNum
                amount={parseFloat(utils.formatEther(depositQuote.toString()))}
              ></TooltipNum>
            ) : (
              '0.00'
            )}
            {` LP-${asset.displaySymbol}`}
          </span>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <span className="mr-2">Pool Share</span>
          </div>
          <span>
            <FormattedNumber
              amount={Number(utils.formatEther(newPoolShare))}
              threshold={0.0001}
              showColor={false}
            ></FormattedNumber>
          </span>
        </div>
        <div className="flex w-full flex-row-reverse">
          <span className="font-Work text-xs text-wombatBrown2">
            {`(1 LP-${asset.displaySymbol} = ${Number(utils.formatEther(exchangeRate ?? 0)).toFixed(
              6
            )} ${token?.displaySymbol})`}
          </span>
        </div>
        {gtOrEqMaxSupply && (
          <div className="mt-6 text-center font-Work text-wombatRed">{`Pool limit ($${getMillifiedFormat(
            maxSupply[currentPool.label][asset.symbol] ?? '0'
          )}) is reached`}</div>
        )}
      </div>
      <Accordion
        title="Risks of using Wombat’s pools"
        message={
          <>
            You should understand the design of Wombat Exchange’s liquidity pools, as well as all
            the risks before you deposit your assets. More information is in the documentation,
            which can be found{' '}
            <u>
              <b>
                <a
                  href="https://docs.wombat.exchange/getting-started/risks"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
              </b>
            </u>
            .
            <br />
            <br />
            By depositing, you confirm that you have read and understand our documentation and that
            you understand and accept all the risks associated with depositing your assets.
          </>
        }
      />
      {buttonGroup()}
      {debounceTokenAmount && account && isAmountGreaterThanBalance() && (
        <div className="mt-2 -mb-2 text-center text-red-600">Insufficient balance.</div>
      )}
      {isDisplayGroupModal && transactionModal()}
    </>
  )
}
