import { HexString } from '../../../interfaces/contract'
import { SupportedChainId } from '../../web3/supportedChainId'
import { Contract } from '../Contract'
import { POOL_ABI } from '../abis/pool'
import { ASSETS } from '../asset'
import { MasterWombatId } from '../masterWombat'
import { TokenSymbol } from '../token/TokenSymbols'
import { PoolLabels } from './PoolLabels'
import { PoolFilterGroup } from './poolFilterGroup'

export type PoolList = { [pool in PoolLabels]?: Pool }
export type PoolConstructorProps = {
  chainId: SupportedChainId
  label: PoolLabels
  address: HexString
  name?: string
  // where is user lp token staked? etc. bnb pool delisted so that user's lp token still at MW2
  masterWombatId?: MasterWombatId
  isHiddenInPoolPage?: boolean
  isShownInBoosterCalculator?: boolean
  filterGroup: PoolFilterGroup
}

export class Pool extends Contract<typeof POOL_ABI> {
  readonly label: PoolLabels
  readonly supportedAssetTokenSymbols: TokenSymbol[]
  readonly name: string
  readonly masterWombatId: MasterWombatId
  readonly isHiddenInPoolPage: boolean
  readonly isShownInBoosterCalculator: boolean
  readonly filterGroup: PoolFilterGroup

  constructor({
    chainId,
    label,
    address,
    name,
    /** @todo May change when new MW update */
    masterWombatId = MasterWombatId.BOOSTED,
    isHiddenInPoolPage = false,
    isShownInBoosterCalculator = false,
    filterGroup,
  }: PoolConstructorProps) {
    super({ address, chainId, abi: POOL_ABI })
    this.label = label
    this.name = name || `${label} Pool`
    this.masterWombatId = masterWombatId
    this.isHiddenInPoolPage = isHiddenInPoolPage
    this.isShownInBoosterCalculator = isShownInBoosterCalculator
    this.supportedAssetTokenSymbols = Object.values(ASSETS[chainId][label]).map(
      (asset) => asset.symbol
    )
    this.filterGroup = filterGroup
  }
}
