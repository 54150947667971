import { SupportedChainId } from '../constants/web3/supportedChainId'
import { TOKENS } from '../constants/contract'
import { PoolLabels } from '../constants/contract/pool/PoolLabels'
import { TokenMaps } from '../constants/contract/token'
import { SwapGroupSymbol, TokenSymbol } from '../constants/contract/token/TokenSymbols'
import { POOLS, TokenPoolMap } from '../constants/contract/pool'
import * as uuidLib from 'uuid'
import { ASSETS } from '../constants/contract/asset'
import { HexString } from '../interfaces/contract'
import { multicall } from '@wagmi/core'
import { ROUTERS } from '../constants/contract/router'
import { ROUTER_ABI } from '../constants/contract/abis/router'
import { BigNumber, utils } from 'ethers'
import { Token } from '../constants/contract/token/Token'
import { getPriceImpactWad } from './swap'
import { executeCallBacks } from './executeCallBacks'
import { ISwapPathData } from '../interfaces/swap'
import { MARKET_FROM_AMOUNT } from '../constants/common'

export type TokenPathInfo = {
  tokenSymbol: TokenSymbol
  poolLabel: PoolLabels
}

/**
 * Get the token infos for the child tokens of the given parent token.
 * @param {SupportedChainId} chainId - The chain id of the parent token.
 * @param {TokenSymbol} parentTokenSymbol - The symbol of the parent token.
 * @param {TokenSymbol} prevParentTokenSymbols - The symbols of the parent tokens that have already been selected.
 * @returns {TokenPathInfo[]} The token infos for the child tokens of the given parent token.
 */
export function getChildTokenInfos(
  chainId: SupportedChainId,
  parentTokenSymbol: TokenSymbol,
  prevParentTokenSymbols: TokenSymbol[]
) {
  const poolLabelsThatContainTokenSymbol = TokenPoolMap[chainId][parentTokenSymbol]
  const tokenInfos = poolLabelsThatContainTokenSymbol.map((poolLabel) => {
    const supportedAssetTokenSymbols = POOLS[chainId][poolLabel]?.supportedAssetTokenSymbols
    return supportedAssetTokenSymbols
      ? supportedAssetTokenSymbols
          .filter((tokenSymbol) => {
            const asset = ASSETS[chainId][poolLabel][tokenSymbol]
            return (
              // Should not include assets that are delisted or paused as they are not available to swap.
              // For example, BNB pool is no longer avaiable to swap. it causes an incorrect route when swapping from BNBx to stkBNBx.
              // It is because BNBx and stkBNBx exist in BNB pool as well.
              !asset?.delisted &&
              !asset?.paused &&
              tokenSymbol !== parentTokenSymbol &&
              !prevParentTokenSymbols.includes(tokenSymbol)
            )
          })
          .map((supportedAssetTokenSymbol) => {
            return {
              tokenSymbol: supportedAssetTokenSymbol,
              poolLabel,
            } as TokenPathInfo
          })
      : []
  })
  return tokenInfos.reduce((prev, curr) => [...prev, ...curr], [])
}

/**
 * Given a chainId, fromTokenSymbol, and toTokenSymbol, return the path of TokenInfo objects that
 * can be used to get from fromTokenSymbol to toTokenSymbol.
 * @param {SupportedChainId} chainId - The chainId of the chain that the tokens are on.
 * @param {TokenSymbol} fromTokenSymbol - The token symbol of the token to start from.
 * @param {TokenSymbol} toTokenSymbol - The token symbol of the token to end at.
 * @param {number} maxPossibleTokenInfoPaths - The maximum depth(Number of pool need to access) to search for the path.
 * @returns {TokenPathInfo[][]} The path of TokenInfo objects that can be used to get from fromTokenSymbol
 */
export function getAllSwapPossibleTokenInfoPaths(
  chainId: SupportedChainId,
  fromTokenSymbol: TokenSymbol,
  toTokenSymbol: TokenSymbol,
  maxPossibleTokenInfoPaths = 10
): TokenPathInfo[][] {
  const tokenInfoPaths: { [id in string]: TokenPathInfo[] } = {}
  for (const tokenInfo of getChildTokenInfos(chainId, fromTokenSymbol, [])) {
    const uuid = uuidLib.v4()
    tokenInfoPaths[uuid] = [tokenInfo]
  }

  const possibleTokenInfoPaths: TokenPathInfo[][] = []
  while (Object.values(tokenInfoPaths).length !== 0) {
    for (const [uuid, tokenInfoPath] of Object.entries(tokenInfoPaths)) {
      const childTokenInfo = tokenInfoPath[tokenInfoPath.length - 1]
      if (childTokenInfo.tokenSymbol === toTokenSymbol) {
        if (possibleTokenInfoPaths.length === maxPossibleTokenInfoPaths) {
          return possibleTokenInfoPaths
        }
        possibleTokenInfoPaths.push(tokenInfoPath)
        delete tokenInfoPaths[uuid]
      } else {
        for (const tokenInfo of getChildTokenInfos(
          chainId,
          childTokenInfo.tokenSymbol,
          tokenInfoPath.map((tokenInfo) => tokenInfo.tokenSymbol)
        )) {
          const newUuid = uuidLib.v4()
          if (
            !tokenInfoPath.some(
              (oldTokenInfo) =>
                (tokenInfo.tokenSymbol === oldTokenInfo.tokenSymbol &&
                  tokenInfo.poolLabel === oldTokenInfo.poolLabel) ||
                tokenInfo.tokenSymbol === oldTokenInfo.tokenSymbol
            )
          ) {
            tokenInfoPaths[newUuid] = [...tokenInfoPath, tokenInfo]
          }
        }
        delete tokenInfoPaths[uuid]
      }
    }
  }
  return possibleTokenInfoPaths
}

/**
 * For a given token and tokenPoolMap:
 * Find the mapped pools using that token and
 * create an array of tokens who are not mapped to any of those pools.
 */
export function getDisabledTokenList(
  tokenSymbol: TokenSymbol,
  chainId: SupportedChainId
): TokenSymbol[] {
  const targetSwapGroupSymbol = TOKENS[chainId][tokenSymbol]?.swapGroupSymbol

  return Object.values(TOKENS[chainId])
    .filter(
      (token) =>
        token.swapGroupSymbol !== targetSwapGroupSymbol &&
        token.swapGroupSymbol !== SwapGroupSymbol.UNAVAILABLE
    )
    .map((token) => token.symbol)
}

/**
 *  Given two arrays find if they have the same information.
 */
export function isEqualAnyOrder(arr1: string[], arr2: string[]): boolean {
  return arr1.length === arr2.length && arr1.every((i) => arr2.includes(i))
}

/**
 *  Given two arrays find if they have some overlap.
 */
export function hasOverlap(arr1: string[], arr2: string[]): boolean {
  return !!arr1.length && !!arr2.length && arr1.some((i) => arr2.includes(i))
}

/**
 * From a given chainId get the tokenList associated with that chainId.
 * These entries are filtered from the data in tokenPoolMap,
 * so that even if we have a token in TokenConfig that is not being used by a pool we don't show it.
 */
export function getFilteredTokenMaps(
  chainId: SupportedChainId,
  requiredAvailable?: boolean,
  isCrossChainAvailable?: boolean
): TokenMaps {
  const filteredTokenMaps: TokenMaps = {}
  for (const pool of Object.values(POOLS[chainId])) {
    pool.supportedAssetTokenSymbols.forEach((tokenSymbol) => {
      const token = TOKENS[chainId][tokenSymbol]
      let availableFlag = true
      if (requiredAvailable) {
        availableFlag = token?.swapGroupSymbol !== SwapGroupSymbol.UNAVAILABLE
      }
      if (isCrossChainAvailable) {
        availableFlag = token?.isCrossChainAvailable === true
      }
      if (token && availableFlag) {
        filteredTokenMaps[tokenSymbol] = token
      }
    })
  }
  // if (chainId === SupportedChainId.BSC_MAINNET) {
  //   console.log({ filteredTokenMaps })
  // }
  return filteredTokenMaps
}

export function tokenInfoPathsToAddresses(
  tokenInfoPaths: TokenPathInfo[],
  fromToken: Token,
  chainId: SupportedChainId
): {
  poolLabelPath: PoolLabels[]
  poolAddresses: `0x${string}`[]
  tokenSymbolPath: TokenSymbol[]
  tokenAddresses: `0x${string}`[]
} {
  const poolLabelPath: PoolLabels[] = []
  const poolAddresses: HexString[] = []
  const tokenSymbolPath: TokenSymbol[] = [fromToken.symbol]
  const tokenAddresses: HexString[] = [fromToken.address]
  tokenInfoPaths.forEach((tokenInfo) => {
    const poolAddress = POOLS[chainId][tokenInfo.poolLabel]?.address
    const tokenAddress = TOKENS[chainId][tokenInfo.tokenSymbol]?.address
    if (poolAddress && tokenAddress) {
      poolLabelPath.push(tokenInfo.poolLabel)
      poolAddresses.push(poolAddress)
      tokenSymbolPath.push(tokenInfo.tokenSymbol)
      tokenAddresses.push(tokenAddress)
    }
  })
  return {
    poolLabelPath,
    poolAddresses,
    tokenSymbolPath,
    tokenAddresses,
  }
}

export type IQuote = {
  potentialOutcome: string
  haircutBNs: BigNumber[]
  priceImpactWad: BigNumber
}
/**
 *
 * @param chainId
 * @param fromToken
 * @param toToken
 * @param allSwapPossibleTokenInfoPaths
 * @param amountBn
 * @param quoteDirection
 * @returns
 */
export async function getBestSwapTokenInfoPaths(
  chainId: SupportedChainId,
  fromToken: Token,
  toToken: Token,
  allSwapPossibleTokenInfoPaths: TokenPathInfo[][],
  amountBn: BigNumber,
  quoteDirection: 'in' | 'out'
): Promise<{
  swapTokenInfoPath: ISwapPathData
  qoute: IQuote
} | null> {
  const isGetAmountOut = quoteDirection === 'out'
  const routerAddress = ROUTERS[chainId]?.address
  if (!routerAddress) return null
  const routerContract = {
    address: routerAddress,
    abi: ROUTER_ABI,
  }
  const contractCalls = []
  const callbacks = []
  const quotedAmountInAllPaths: { [tokenInfoPathsStr in string]: string } = {}
  const haircutsBnInAllPaths: { [tokenInfoPathsStr in string]: BigNumber[] } = {}
  const marketQuotedAmountInAllPaths: { [tokenInfoPathsStr in string]: string } = {}
  /** if isGetAmountOut=true, bestQuotedAmount is better to be bigger, else smaller */
  let bestQuotedAmount: null | number = null
  let bestTokenInfoPathsStr: string | null = null
  const marketAmount = utils.parseUnits(
    MARKET_FROM_AMOUNT,
    isGetAmountOut ? fromToken.decimals : toToken.decimals
  )
  for (const tokenInfoPaths of allSwapPossibleTokenInfoPaths) {
    const tokenInfoPathsStr = JSON.stringify(tokenInfoPaths)
    const { poolAddresses, tokenAddresses } = tokenInfoPathsToAddresses(
      tokenInfoPaths,
      fromToken,
      chainId
    )
    if (isGetAmountOut) {
      contractCalls.push(
        {
          ...routerContract,
          functionName: 'getAmountOut',
          args: [tokenAddresses, poolAddresses, amountBn],
        },
        {
          ...routerContract,
          functionName: 'getAmountOut',
          args: [tokenAddresses, poolAddresses, marketAmount],
        }
      )
      callbacks.push(
        (value: [BigNumber, BigNumber[]]) => {
          const [quotedAmountBn, haircutsBn] = value
          const quotedAmount = utils.formatUnits(quotedAmountBn, toToken.decimals)
          quotedAmountInAllPaths[tokenInfoPathsStr] = quotedAmount
          haircutsBnInAllPaths[tokenInfoPathsStr] = haircutsBn
          /** More to token is better */
          if (bestQuotedAmount === null || Number(quotedAmount) > bestQuotedAmount) {
            bestQuotedAmount = Number(quotedAmount)
            bestTokenInfoPathsStr = tokenInfoPathsStr
          }
        },
        (value: [BigNumber, BigNumber[]]) => {
          const marketQuotedAmountBn = value[0]
          marketQuotedAmountInAllPaths[tokenInfoPathsStr] = utils.formatUnits(
            marketQuotedAmountBn,
            toToken.decimals
          )
        }
      )
    } else {
      contractCalls.push(
        {
          ...routerContract,
          functionName: 'getAmountIn',
          args: [tokenAddresses, poolAddresses, amountBn],
          chainId,
        },
        {
          ...routerContract,
          functionName: 'getAmountIn',
          args: [tokenAddresses, poolAddresses, marketAmount],
          chainId,
        }
      )
      callbacks.push(
        (value: [BigNumber, BigNumber[]]) => {
          const [quotedAmountBn, haircutsBn] = value
          const quotedAmount = utils.formatUnits(quotedAmountBn, fromToken.decimals)
          quotedAmountInAllPaths[tokenInfoPathsStr] = quotedAmount
          haircutsBnInAllPaths[tokenInfoPathsStr] = haircutsBn
          /** Less from token is better */
          if (bestQuotedAmount === null || Number(quotedAmount) < bestQuotedAmount) {
            bestQuotedAmount = Number(quotedAmount)
            bestTokenInfoPathsStr = tokenInfoPathsStr
          }
        },
        (value: [BigNumber, BigNumber[]]) => {
          const marketQuotedAmountBn = value[0]
          marketQuotedAmountInAllPaths[tokenInfoPathsStr] = utils.formatUnits(
            marketQuotedAmountBn,
            fromToken.decimals
          )
        }
      )
    }
  }
  const values = (await multicall({ contracts: contractCalls, chainId })) as BigNumber[][]
  executeCallBacks(values, callbacks)
  if (bestTokenInfoPathsStr) {
    const bestTokenInfoPaths = JSON.parse(bestTokenInfoPathsStr) as TokenPathInfo[]
    return {
      swapTokenInfoPath: tokenInfoPathsToAddresses(bestTokenInfoPaths, fromToken, chainId),
      qoute: {
        potentialOutcome: quotedAmountInAllPaths[bestTokenInfoPathsStr],
        haircutBNs: haircutsBnInAllPaths[bestTokenInfoPathsStr],
        priceImpactWad: isGetAmountOut
          ? getPriceImpactWad(
              utils.formatUnits(amountBn, fromToken.decimals),
              quotedAmountInAllPaths[bestTokenInfoPathsStr],
              MARKET_FROM_AMOUNT,
              marketQuotedAmountInAllPaths[bestTokenInfoPathsStr]
            )
          : getPriceImpactWad(
              quotedAmountInAllPaths[bestTokenInfoPathsStr],
              utils.formatUnits(amountBn, toToken.decimals),
              marketQuotedAmountInAllPaths[bestTokenInfoPathsStr],
              MARKET_FROM_AMOUNT
            ),
      },
    }
  }
  return null
}
