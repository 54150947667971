export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'
export const DELAY_TIME_ANIMATION_GIF = 1500
export enum BUILD_TYPE {
  STAGING = 'STAGING',
  PROD = 'PROD',
}
export const WAD_DECIMALS = 18
// APIs
export const COIN_GECKO_API = 'https://api.coingecko.com/api/v3/simple/price'

/**@todo replace with dynamic market quote from amount */
export const INFINITESIMAL_SWAP_FROM_AMOUNT_FOR_MARKET = '0.1'
export const SECONDS_PER_WEEK = 60 * 60 * 24 * 7

export const BRIDGE_TRANSFER_PROGRESS_LIMIT = 90
export const SECONDS_IN_YEAR = 60 * 60 * 24 * 365

// Terms and condition
export const SECONDS_PER_DAY = 60 * 60 * 24
export const TNC_CACHED_DAY = 30

export const MARKET_FROM_AMOUNT = '0.1'

// Crosschain swap
export const CROSS_CHAIN_SWAP_GAS_LIMIT = '200000'
export const MARKET_CREDIT = '0.1'
