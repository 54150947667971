import { BigNumber, utils } from 'ethers'
import { ZERO_ADDRESS } from '../../../constants/common'
import { LATEST_MASTER_WOMBATS } from '../../../constants/contract'
import { ASSETS } from '../../../constants/contract/asset'
import { BRIBE_FACTORIES } from '../../../constants/contract/bribeFactory'
import { BoostedMultiRewarder } from '../../../constants/contract/multiRewarder'
import { POOLS } from '../../../constants/contract/pool'
import { PoolLabels } from '../../../constants/contract/pool/PoolLabels'
import { TOKENS, tokenAddressTokenMap } from '../../../constants/contract/token'
import { TokenSymbol } from '../../../constants/contract/token/TokenSymbols'
import { SupportedChainId } from '../../../constants/web3/supportedChainId'
import { PoolLabelsTokenSymbolsGenericType } from '../../../interfaces/common'
import { HexString } from '../../../interfaces/contract'
import { AssetProperty, getInitialAssetProperty } from '../../../utils/asset'
import { CallbacksType, IContractCalls } from '../../../utils/executeCallBacks'

/**
 * 1st multicall
 */
export function fetchBoostedPoolRewarderAddressesOn1stMulticall(
  chainId: SupportedChainId,
  onlyXChain?: boolean
) {
  const contractCalls: IContractCalls = []
  const callbacks: CallbacksType = []
  const masterWombatContract = LATEST_MASTER_WOMBATS[chainId]
  const assets = ASSETS[chainId]
  Object.entries(assets).forEach(([poolLabel, assetsInPool]) => {
    if (onlyXChain && poolLabel !== PoolLabels.CROSS_CHAIN) return
    const pool = POOLS[chainId][poolLabel as PoolLabels]
    const supportedAssetTokenSymbols = pool?.supportedAssetTokenSymbols
    if (!supportedAssetTokenSymbols || pool?.masterWombatId !== masterWombatContract.masterWombatId)
      return
    Object.values(assetsInPool).forEach((asset) => {
      if (asset.pid === -1 || !supportedAssetTokenSymbols.includes(asset.symbol)) return
      const { pid } = asset
      contractCalls.push(masterWombatContract.multicall('boostedRewarders', [pid]))
      callbacks.push((value) => {
        asset.boostedPoolRewarder = {
          ...asset.boostedPoolRewarder,
          rewarderAddress: value,
        }
      })
    })
  })

  return { contractCalls, callbacks }
}

/**
 * 2nd multicall
 *
 */
export function fetchBoostedPoolRewarderDataOn2ndMulticall(
  chainId: SupportedChainId,
  onlyXChain?: boolean
) {
  const contractCalls: IContractCalls = []
  const callbacks: CallbacksType = []
  const masterWombatContract = LATEST_MASTER_WOMBATS[chainId]
  const assets = ASSETS[chainId]
  Object.entries(assets).forEach(([poolLabel, assetsInPool]) => {
    if (onlyXChain && poolLabel !== PoolLabels.CROSS_CHAIN) return
    const pool = POOLS[chainId][poolLabel as PoolLabels]
    const supportedAssetTokenSymbols = pool?.supportedAssetTokenSymbols
    if (!supportedAssetTokenSymbols || pool?.masterWombatId !== masterWombatContract.masterWombatId)
      return
    Object.values(assetsInPool).forEach((asset) => {
      if (asset.pid === -1 || !supportedAssetTokenSymbols.includes(asset.symbol)) return
      const boostedPoolRewarder = asset.boostedPoolRewarder
      const BoostedPoolRewarderAddress = boostedPoolRewarder?.rewarderAddress
      if (!BoostedPoolRewarderAddress || BoostedPoolRewarderAddress === ZERO_ADDRESS) return
      const boostedMultiRewarderContract = new BoostedMultiRewarder({
        chainId,
        address: BoostedPoolRewarderAddress,
      })

      /**
       * Reward tokens & Reward tokenSymbols
       */
      contractCalls.push(boostedMultiRewarderContract.multicall('rewardTokens', []))
      callbacks.push((rewardTokenAddresses: HexString[]) => {
        const rewardTokenSymbols: TokenSymbol[] = []
        for (let i = 0; i < rewardTokenAddresses.length; i++) {
          const rewardTokenAddress = rewardTokenAddresses[i]
          if (!tokenAddressTokenMap[chainId]?.[rewardTokenAddress.toLowerCase()])
            console.error(`${rewardTokenAddress} not in tokenAddressTokenMap`)
          rewardTokenSymbols.push(
            tokenAddressTokenMap[chainId]?.[rewardTokenAddress.toLowerCase()]?.symbol as TokenSymbol
          )
        }

        boostedPoolRewarder.rewardTokenAddresses = rewardTokenAddresses
        boostedPoolRewarder.rewardTokenSymbols = rewardTokenSymbols
      })
    })
  })
  return { contractCalls, callbacks }
}

/**
 * 3rd multicall
 */
export type BoostedPoolRewarderDataWithoutAccountType = {
  rewardTokenPerSec: PoolLabelsTokenSymbolsGenericType<
    { tokenSymbol: TokenSymbol; value: string }[]
  >
  rewardTokenBalance: PoolLabelsTokenSymbolsGenericType<
    { tokenSymbol: TokenSymbol; value: string }[]
  >
  rewardTokenSurplus: PoolLabelsTokenSymbolsGenericType<
    { tokenSymbol: TokenSymbol; value: string }[]
  >
  rewardTokensRunoutTimestamps: PoolLabelsTokenSymbolsGenericType<
    { tokenSymbol: TokenSymbol; value: number }[]
  >
  // if a reward has an inactive emission, it is either not started or has ended
  isEmissionActive: PoolLabelsTokenSymbolsGenericType<
    { tokenSymbol: TokenSymbol; value: boolean }[]
  >
}
export interface UserRewardInfo {
  rewardDebt: BigNumber
  unpaidRewards: BigNumber
}
export interface BoostedPoolRewarderDataWithAccountType {
  hasOperatorRoleOfEachAsset: AssetProperty<boolean>
  hasDeployerRoleOfEachAsset: AssetProperty<boolean>
  pendingTokens: AssetProperty<string[]>
  userRewardInfo: AssetProperty<UserRewardInfo>
}
export function fetchBoostedPoolRewarderDataOn3rdMulticall(
  chainId: SupportedChainId,
  account: HexString | null,
  onlyXChain?: boolean
) {
  const contractCalls: IContractCalls = []
  const callbacks: CallbacksType = []
  const states: {
    withoutAccount: BoostedPoolRewarderDataWithoutAccountType
    withAccount: BoostedPoolRewarderDataWithAccountType
  } = {
    withoutAccount: {
      rewardTokenPerSec: {},
      rewardTokenBalance: {},
      rewardTokenSurplus: {},
      rewardTokensRunoutTimestamps: {},
      isEmissionActive: {},
    },
    withAccount: {
      // from pool rewarder
      hasOperatorRoleOfEachAsset: getInitialAssetProperty<boolean>(),
      // from rewarder factory
      hasDeployerRoleOfEachAsset: getInitialAssetProperty<boolean>(),
      pendingTokens: getInitialAssetProperty<string[]>(),
      userRewardInfo: getInitialAssetProperty<UserRewardInfo>(),
    },
  }
  const masterWombatContract = LATEST_MASTER_WOMBATS[chainId]
  const assets = ASSETS[chainId]
  Object.entries(assets).forEach(([poolLabelStr, assetsInPool]) => {
    if (onlyXChain && poolLabelStr !== PoolLabels.CROSS_CHAIN) return
    const poolLabel = poolLabelStr as PoolLabels
    const pool = POOLS[chainId][poolLabel]
    const supportedAssetTokenSymbols = pool?.supportedAssetTokenSymbols
    if (!supportedAssetTokenSymbols || pool?.masterWombatId !== masterWombatContract.masterWombatId)
      return
    Object.values(assetsInPool).forEach((asset) => {
      if (asset.pid === -1 || !supportedAssetTokenSymbols.includes(asset.symbol)) return
      const boostedPoolRewarder = asset.boostedPoolRewarder
      // if (pool?.label === PoolLabels.SIDE) {
      //   console.log(asset, boostedPoolRewarder)
      // }
      const BoostedPoolRewarderAddress = boostedPoolRewarder?.rewarderAddress
      const assetSymbol = asset.symbol
      const hasBoostedPoolRewarder =
        BoostedPoolRewarderAddress && BoostedPoolRewarderAddress !== ZERO_ADDRESS
      /**
       * Boosted Rewarder
       */
      let boostedMultiRewarderContract: BoostedMultiRewarder | null = null
      if (hasBoostedPoolRewarder) {
        boostedMultiRewarderContract = new BoostedMultiRewarder({
          chainId,
          address: BoostedPoolRewarderAddress,
        })
      }
      if (
        boostedMultiRewarderContract &&
        hasBoostedPoolRewarder &&
        boostedPoolRewarder.rewardTokenSymbols
      ) {
        for (let i = 0; i < boostedPoolRewarder.rewardTokenSymbols.length; i++) {
          const rewardTokenSymbol = boostedPoolRewarder.rewardTokenSymbols[i]
          const rewardToken = TOKENS[chainId][rewardTokenSymbol]
          const rewardDecimals = rewardToken?.decimals
          if (!rewardDecimals) continue
          /**
           * Reward Infos
           */
          contractCalls.push(boostedMultiRewarderContract.multicall('rewardInfos', [i]))
          callbacks.push((value) => {
            states.withoutAccount.rewardTokenPerSec[poolLabel] = {
              ...(states.withoutAccount.rewardTokenPerSec[poolLabel] || {}),
              [assetSymbol]: [
                ...(states.withoutAccount.rewardTokenPerSec[poolLabel]?.[assetSymbol] || []),
                {
                  tokenSymbol: rewardTokenSymbol,
                  value: utils.formatUnits(value.tokenPerSec, rewardDecimals),
                },
              ],
            }
          })
          /**
           * Reward Token Balance
           */
          contractCalls.push(boostedMultiRewarderContract.multicall('balances', []))
          callbacks.push((balances: BigNumber[]) => {
            states.withoutAccount.rewardTokenBalance[poolLabel] = {
              ...(states.withoutAccount.rewardTokenBalance[poolLabel] || {}),
              [assetSymbol]: [
                ...(states.withoutAccount.rewardTokenBalance[poolLabel]?.[assetSymbol] || []),
                {
                  tokenSymbol: rewardTokenSymbol,
                  value: utils.formatUnits(balances[i], rewardDecimals),
                },
              ],
            }
          })
          /**
           * Reward Token Surpluse
           */
          contractCalls.push(boostedMultiRewarderContract.multicall('rewardTokenSurpluses', []))
          callbacks.push((surplus: BigNumber[]) => {
            states.withoutAccount.rewardTokenSurplus[poolLabel] = {
              ...(states.withoutAccount.rewardTokenSurplus[poolLabel] || {}),
              [assetSymbol]: [
                ...(states.withoutAccount.rewardTokenSurplus[poolLabel]?.[assetSymbol] || []),
                {
                  tokenSymbol: rewardTokenSymbol,
                  value: utils.formatUnits(surplus[i], rewardDecimals),
                },
              ],
            }
          })
          /**
           * Reward Token Runout timestamp
           */
          contractCalls.push(boostedMultiRewarderContract.multicall('runoutTimestamps', []))
          callbacks.push((runoutTimestamps: number[]) => {
            states.withoutAccount.rewardTokensRunoutTimestamps[poolLabel] = {
              ...(states.withoutAccount.rewardTokensRunoutTimestamps[poolLabel] || {}),
              [assetSymbol]: [
                ...(states.withoutAccount.rewardTokensRunoutTimestamps[poolLabel]?.[assetSymbol] ||
                  []),
                {
                  tokenSymbol: rewardTokenSymbol,
                  value: runoutTimestamps[i],
                },
              ],
            }
          })
          /**
           * isEmissionActive
           */
          contractCalls.push(boostedMultiRewarderContract.multicall('isEmissionActive', []))
          callbacks.push((isEmissionActive: boolean[]) => {
            states.withoutAccount.isEmissionActive[poolLabel] = {
              ...(states.withoutAccount.isEmissionActive[poolLabel] || {}),
              [assetSymbol]: [
                ...(states.withoutAccount.isEmissionActive[poolLabel]?.[assetSymbol] || []),
                {
                  tokenSymbol: rewardTokenSymbol,
                  value: isEmissionActive[i],
                },
              ],
            }
          })
        }
      }

      /**
       * with account
       */
      if (account) {
        if (boostedMultiRewarderContract) {
          if (account) {
            contractCalls.push(boostedMultiRewarderContract.multicall('pendingTokens', [account]))
            callbacks.push((pendingTokens: BigNumber[]) => {
              states.withAccount.pendingTokens[poolLabel][assetSymbol] = pendingTokens.map(
                (p, i) => {
                  const rewardTokenSymbol = boostedPoolRewarder?.rewardTokenSymbols?.[i]

                  if (!rewardTokenSymbol) return '0'
                  const rewardToken = TOKENS[chainId][rewardTokenSymbol]
                  const rewardDecimals = rewardToken?.decimals

                  if (!rewardDecimals) return '0'
                  return utils.formatUnits(p, rewardDecimals)
                }
              )
            })

            const rewardTokenAddresses = boostedPoolRewarder?.rewardTokenAddresses || []
            const rewardTokenSymbols = boostedPoolRewarder?.rewardTokenSymbols || []

            for (let i = 0; i < rewardTokenAddresses.length; i++) {
              const rewardTokenSymbol = rewardTokenSymbols[i]
              contractCalls.push(
                boostedMultiRewarderContract.multicall('userRewardInfo', [i, account])
              )
              callbacks.push((value) => {
                states.withAccount.userRewardInfo[poolLabel][rewardTokenSymbol] = value
              })
            }
          }
          /**
           * Rewarder operator
           */
          const roleOperator = utils.keccak256(utils.toUtf8Bytes('operator'))
          contractCalls.push(
            /** Check if the user has an operator role */
            boostedMultiRewarderContract.multicall('hasRole', [roleOperator, account])
          )
          callbacks.push((hasRole: boolean) => {
            states.withAccount.hasOperatorRoleOfEachAsset[poolLabel] = {
              ...(states.withAccount.hasOperatorRoleOfEachAsset[poolLabel] || {}),
              [assetSymbol]: hasRole,
            }
          })
        }

        /**
         * Rewarder deployer
         */
        const bribeFactoryContract = BRIBE_FACTORIES[chainId]
        if (bribeFactoryContract) {
          contractCalls.push(bribeFactoryContract.multicall('rewarderDeployers', [asset.address]))
          callbacks.push((value) => {
            states.withAccount.hasDeployerRoleOfEachAsset[poolLabel] = {
              ...(states.withAccount.hasDeployerRoleOfEachAsset[poolLabel] || {}),
              [assetSymbol]: value === account,
            }
          })
        }
      }
    })
  })
  return { contractCalls, callbacks, states }
}
